.dashboard{
    background: linear-gradient( rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
    position: relative;
    backdrop-filter: blur(15px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.dashboard__body{
    width: 100%;
    height: 92%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.5s ease-in-out;
    z-index: 0;
}
.orbit__viewer__container{
    width: calc(100vw - 300px);
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
}
.orbit__viewer__container__full{
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
}
.dashboard__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 8%;
    width: 100%;
    background: #000;
    z-index: 99;
    padding: 0 30px;
    .logo__section{
        width: 15%;
        height: 20px;
        background: #000;
        transform: skew(-55deg);
        position: absolute;
        top: -17px;
        right: -2.9%;
        z-index: 9;
    }
}

.footer__svg__container {
    width: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: -19px;
    z-index: 10;
    rotate: 180deg;
}


.footer-btn-list{
    border-radius: 4px;
    overflow: hidden;
}
.footer-tab-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    color: #F0F0F0;
    font-size: 14px;
    gap: 12px;
    background-color: transparent;
    border-radius: 2px;
    transition: background-color 0.3s, color 0.3s; /* Add a smooth transition */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: rgba(255,255,255,0.1);
}

.footer-tab-btn.active{
    background-color: #CCF54E;
    color: #000000;
}


.footer-btn-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24%;
}
.footer-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 33px;
    padding: 15px 20px;
    color: #FF2929;
    font-size: 14px;
    border:0.5px solid #FF2929;
    border-radius: 4px;
    background-color: transparent;
}
.footer-btn:hover{
    background-color: rgba(103, 231, 100, 0.2) ;
}

