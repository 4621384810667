.coverage_analysis_container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--none, 0pt) 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 0.69);
    box-shadow: 0pt 0pt 20pt 0pt rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125pt);
}

.coverage_analysis_container_body{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: rgba(204, 245, 78, 0.05);
}

.inner_section{
    display: flex;
    padding: 8pt var(--12, 12pt) var(--12, 12pt) var(--12, 12pt);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
}

.ground_station_section{
    display: flex;
    padding: 4pt var(--12, 12pt) var(--12, 12pt) var(--12, 12pt);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
    border-bottom: 0.5pt solid #F0F0F0;
}

.other_section{
    display: flex;
    padding: var(--12, 12pt) 0pt;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
    border-bottom: 0.5pt solid #F0F0F0;
}

.section_title{
    display: flex;
    align-items: center;
    gap: 4pt;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 13pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.45;
    }
}

.geofense_section_title{
    display: flex;
    align-items: center;
    gap: 4pt;
    span{
        color: #7AB3FF;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.inner_container{
    display: flex;
    width: 100%;
    min-height: 35pt;
    max-height: 105pt;
    align-content: start;
    overflow-y: auto;
    padding: 8pt;
    gap: var(--12, 12pt);
    flex: 1 0 0;
    flex-wrap: wrap;
    border: 0.5pt solid #6C6C6C;
    background: linear-gradient(0deg, rgba(10, 10, 10, 0.65) 0%, rgba(10, 10, 10, 0.65) 100%), rgba(240, 255, 192, 0.02);
}

.single_view{
    display: flex;
    padding: 4pt 8pt;
    align-items: center;
    gap: 10pt;
    border-radius: 4pt;
    background: rgba(242, 169, 59, 0.25);
    span{
        color: var(--Primary_sat, #F2A93B);
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}


.geofense_single_view{
    display: flex;
    padding: 4pt 8pt;
    align-items: center;
    gap: 10pt;
    border-radius: 4pt;
    background: rgba(122, 179, 255, 0.25);
    span{
        color: #7AB3FF;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.timeframe_section{
    display: flex;
    padding: 4pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.immaging_comm_section{
    display: flex;
    padding: var(--12, 12pt) 4pt;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 0.1px solid transparent;
    border-image: linear-gradient(135deg, #fff, #000) 1;
    background: rgba(255, 255, 255, 0.00);
}

.immaging_com_subsection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4pt;
    flex: 1 0 0;
}

.imagging_com_section_svg_container{
    display: flex;
    width: 46pt;
    height: 46pt;
    padding: 8pt;
    justify-content: center;
    align-items: center;
    gap: 8pt;
    border-radius: 4pt;
    box-shadow: 0pt 4pt 4pt 0pt rgba(0, 0, 0, 0.25);
}

.active_container{
    border: 0.5pt solid #71804F;
    background: #262B1A;
}

.container_text{
    color: rgba(240, 240, 240, 0.80);
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    line-height: 21pt; /* 175% */
}

.active_container_text{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: 21pt; /* 175% */
}

.disable_text{
    opacity: 0.3;
}