.command__center_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 55px);
    width: 100%;
    position: relative;
    overflow: hidden;
    // background-image: url('/public/operate.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.spin__svg {
  animation-name: spin_icon;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin_icon {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}