.ground_station_main_modal{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--none, 0pt) 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 0.69);
    box-shadow: 0pt 0pt 20pt 0pt rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125pt);
}

.ground_station_creation_modal_body{
    width: 100%;
    display: flex;
    padding: 8pt var(--12, 12pt);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: rgba(204, 245, 78, 0.05);
}

.ground_station_creation_item{
    display: flex;
    width: 100%;
    padding: 4pt 0pt;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


.comms_panel{
    display: flex;
    padding: 4pt 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
}

.comms_panel_title{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 13pt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 13pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.45;
    }
}

.comms_panel_band_combination{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 4pt;
    padding: 4pt;
    align-self: stretch;

    span{
        color: rgba(240, 240, 240, 0.80);
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}

.comms_panel_band{
    width: 33%;
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
}

.wd_panel_band{
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
}
