.messageContainer{
    display: inline-flex;
    position: absolute;
    top: 21pt;
    right: 60pt;
    padding: 8pt;
    align-items: center;
    gap: 8pt;
    border-radius: 4px;
    border: 0.5px solid #253003;
    background: rgba(47, 54, 26, 0.10);
    box-shadow: 0pt 4pt 20pt 0pt rgba(15, 5, 41, 0.25);
    backdrop-filter: blur(25pt);
}

.error_message_container{
    border-radius: 4px;
    border: 0.5px solid #330404;
    background: rgba(51, 4, 4, 0.10);
    box-shadow: 0px 4px 20px 0px rgba(15, 5, 41, 0.25);
    backdrop-filter: blur(25px);
}

.message_alignment{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 4pt;
}

.success_message_text{
    color: #CCF54E;
    text-align: center;
    font-family: Montserrat;
    font-size: 14pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.error_message_text{
    color: #F33;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

