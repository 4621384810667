.empty_ground__contact__container{
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    width: 100%;
    .empty__contact__section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        gap: 20px;
        flex: 1;

        .empty__contact__section__content{
            font-size: 20px;
            color: #F0F0F0;
            font-family: Montserrat;
            opacity: 0.6;
            font-weight: 500;
        }
    }
}