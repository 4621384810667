.backpanel{
    display: flex;
    padding: 8pt 4pt 8pt 8pt;
    align-items: center;
    height: auto;
    align-self: stretch;
    background: rgba(204, 245, 78, 0.05);
}

.svgContainerBackPanel{
    display: flex;
    width: 18pt;
    height: 18pt;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10pt;
}

.pointing_cursor{
    cursor: pointer;
}

.modelHeadingStyle{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 11pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.165pt;
}