.command__center_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* border-style: none;
    border-width: 0; */
    /* box-shadow: 0px 1px 10px #3b3b3b6f; */
    box-shadow: 0px 1px 7px #ffffff6f;
    background: black;
    height: 60px;
    z-index: 9999;
    padding: 0px 16px;
    position: relative;
    /* border-top: 0.5px solid;
    border-image: linear-gradient(to left, #4a4949,#4a4949, transparent); 
    border-image-slice: 1; */
    &.disabled{
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}

.scenario__timer__container{
    position: absolute;
    background: black;
    min-width: 300px;
    height: 100%;
    right: 170px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.scenario__timer__container .time__container{
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
    gap: 3px;
    text-align: start;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &:nth-child(2){
            opacity: 0.6;
            font-size: 12px;
        }
    }
}
.scenario__timer__container .separator{
    width: 1px;
    height: 40px;
    margin-top: 15px;
    background-color: rgba(240, 240, 240, 0.20);
}

.footer__left_button_container,
.footer__right_button_container{
    display: flex;
    align-items: center;
    gap: 12px;
}

.footer__left__button{
    display: flex;
    flex-direction: column;
    background: transparent;
    padding: 0;
    position: relative;
    span{
        color: #CCF54E;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    };
    span:nth-child(2){
        color: #f0f0f0;
        font-size: 12px;
        font-weight: 400;
    }
    
}

.footer__right_button{
    display: inline-flex;
    padding: 6px 16px;
    justify-content: center;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #FF2929;
    background: transparent;
    color: #FF2929;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:disabled{
        opacity: 0.5;
        cursor: default;
    }
}
.footer__center_button_container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(240, 240, 240, 0.10);
    border-radius: 4px;
    overflow: hidden;
}
.footer__center__button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B7B7B7;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    gap: 8px;
    background: transparent;
    transition: all 0.5s ease;
}
.footer__center__button:disabled{
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
}
.footer__center__button.hide{
    display: none;
}
.footer__center__button:hover{
    background: rgba(204, 245, 78, 0.1);
}
.footer__center__button.active{
    background: rgba(204, 245, 78, 0.20);
    color: #CCF54E;
    svg{
        fill: #CCF54E;
        stroke: #CCF54E;
        stroke-width: 0.5px;
    }
}