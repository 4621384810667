.schedule__panel__container{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    height: 100%;
    width: 350px;
    overflow: hidden;
    transition: all 0.3s ease;
    &.expand{
        width: 800px;
        .schedule__panel__header {
            .panel__colapse__btn{
                transform: rotate(180deg);
            }
        } 
    };
    .schedule__panel__header{
        display: flex;
        padding: var(--8, 8px);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 0.5px solid rgba(240, 240, 240, 0.20);
        .panel__header__title{
            color: #CCF54E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        };
        .panel__close__btn,
        .panel__pin__btn,
        .panel__colapse__btn{
            background: transparent;
            transform: rotate(0deg);
            transition: all 0.3s ease;
            display: flex;
            width: var(--24, 24px);
            height: var(--24, 24px);
            padding: var(--4, 4px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
        };
        .panel__pin__btn.pinned{
            border-radius: var(--4, 4px);
            background: rgba(204, 245, 78, 0.10);
            svg{
                transform: rotate(-45deg);
                stroke: #CCF54E;
                stroke-width: 0.5px;
                transition: all 0.3s ease;
            }
        }
    };
    .schedule__panel__body__container{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        width: 100%;
        height: calc(100vh - 180px);
        gap: 5px;
        .panel__container__header{
            width: 798px;
            display: flex;
            padding: var(--8, 8px) var(--12, 12px);
            align-items: center;
            justify-content: space-between;
            align-self: stretch;
            background: rgba(240, 240, 240, 0.10);
            .container__header__item{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #F0F0F0;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            };
            .container__header__item:first-child{
                justify-content: start;
                width: 340px;
            }
            .container__header__item:nth-child(2){
                width: 220px;
            }
            .container__header__item:nth-child(3){
                width: 220px;
            }
        };
        .panel__container__body{
            width: 795px;
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            gap: 5px;
            .body__item__separator{
                width: 100%;
                display: flex;
                background: rgba(240, 240, 240, 0.20);
                height: 0.1px;
            }
            .body__item__container{
                width: 100%;
                display: flex;
                align-items: start;
                flex-direction: column;
                align-self: stretch;
                transition: all 0.3s ease;  
                cursor: pointer;
                .item__details__header__container{
                    width: 100%;
                    display: flex;
                    position: relative;
                    align-items: center;
                    padding: 12px;
                    justify-content: space-between;
                    border-radius: 4px;
                    .container__header__item{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #F0F0F0;
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    };
                    .container__header__item:first-child{
                        justify-content: start;
                        width: 340px;
                        gap: 8px;
                        .icon__container{
                            display: flex;
                            align-items: center;
                            gap: 12px;
                        };
                        .details__container{
                            display: flex;
                            flex-direction: column;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            text-align: start;
                            width: 80%;
                            span{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                            }
                            span:nth-child(1){
                                color: #F0F0F0;
                            };
                            span:nth-child(2){
                                color: #A5A5A5;
                                white-space: nowrap;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    };
                    .container__header__item:nth-child(2){
                        width: 220px;
                        span{
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                            opacity: 0.6;
                        }
                    };
                    .container__header__item:nth-child(3){
                        width: 220px;
                        span{
                            font-size: 12px;
                            opacity: 0.6;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                            opacity: 0.6;
                        }
                    };
                    &.running{
                        &::after{
                            content: '';
                            height: 100%;
                            width: 2px;
                            border-radius: 2px;
                            position: absolute;
                            left: 0;
                            background: #CCF54E;
                        }
                    };
                }

                .item__details__body__container{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    height: 0px;
                    overflow: hidden;
                    transition: all 0.3s ease;
                    gap: 5px;
                    .details__body__container__header{
                        width: 100%;
                        display: flex;
                        position: relative;
                        align-items: center;
                        padding: 12px;
                        justify-content: space-between;
                        border: 0.5px solid rgba(240, 240, 240, 0.4);
                        border-radius: 8px;
                        .details__body__container__header__item{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #F0F0F0;
                            text-align: center;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        };
                        .details__body__container__header__item:first-child{
                            justify-content: start;
                            width: 340px;
                        };
                        .details__body__container__header__item:nth-child(2){
                            width: 220px;
                        };
                        .details__body__container__header__item:nth-child(3){
                            width: 220px;
                        };
                    };
                    .details__body__container__body__container{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        .details__body__container__body__container__separator{
                            width: 100%;
                            display: flex;
                            background: rgba(240, 240, 240, 0.20);
                            height: 0.1px;
                            &:last-child{
                                display: none;
                            }
                        }
                        .details__body__container__body{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            padding: var(--8, 8px) var(--12, 12px);
                            justify-content: space-between;
                            position: relative;
                            .details__body__container__body__item{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #F0F0F0;
                                text-align: center;
                                font-family: Montserrat;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            };
                            .details__body__container__body__item:first-child{
                                justify-content: start;
                                width: 340px;
                                gap: 8px;
                                .icon__container{
                                    display: flex;
                                    align-items: center;
                                    gap: 12px;
                                };
                                .details__container{
                                    display: flex;
                                    flex-direction: column;
                                    font-family: Montserrat;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    text-align: start;
                                    width: 80%;
                                    span{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 100%;
                                    }
                                    span:nth-child(1){
                                        color: #F0F0F0;
                                    };
                                    span:nth-child(2){
                                        color: #A5A5A5;
                                        white-space: nowrap;
                                        width: 100%;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                            .details__body__container__body__item:nth-child(2){
                                width: 220px;
                                span{
                                    font-size: 10px;
                                    opacity: 0.6;
                                }
                            }
                            .details__body__container__body__item:nth-child(3){
                                width: 220px;
                                span{
                                    font-size: 10px;
                                    opacity: 0.6;
                                }
                            }
                            &.running{
                                &::after{
                                    content: '';
                                    height: 100%;
                                    width: 2px;
                                    border-radius: 2px;
                                    position: absolute;
                                    left: 0;
                                    background: #CCF54E;
                                }
                            };
                        }
                    }
                }

                &.active{
                    max-height: 400px;
                    cursor: pointer;
                    gap: 5px;
                    .item__details__header__container{
                        background: rgba(204, 245, 78, 0.10);
                        &.running{
                            &::after{
                                content: '';
                                height: 100%;
                                width: 2px;
                                border-radius: 2px;
                                position: absolute;
                                left: 0;
                                background: #CCF54E;
                            }
                        };
                    };
                    .item__details__body__container{
                        flex: 1;
                        padding: 8px 12px;
                        overflow-y: scroll;
                        scrollbar-width: none;
                        background: rgba(204, 245, 78, 0.05);
                        border-radius: 4px;
                    }
                }

            }
        }
    }
}
