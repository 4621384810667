/* .horizontalMenucontainer {
    background-image: url("../../../src/assets/images/svgs/earth_background.svg") !important;
    background-size: cover;
    z-index: -1;
} */

.earthImageBackground {
    /* background-image: url("../../../src/assets/images/svgs/earth_background.svg"); */
    background-size: cover;
    position: absolute;
    height: 100%;
    top: 0px;
}

.Mission-modelling-loader{
    height: 900px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 1, 0.5) 0%, rgba(2, 35, 59, 0.185)93.39%);
    backdrop-filter: blur(20px);
    z-index: 999;
    }

    .orbit-creating-text-container{
        display: flex;
        flex-direction: column;
        font-size: 20px;
        color: rgba(232, 255, 255, 1);
        font-weight: 400;
    }
  
/* body.transparent-mode.bg-img2 {
    background-image: url("../../../src/assets/images/svgs/earth_background.svg") !important;
} */

/* .Header_Header__mjYBN{
    background-color: black;
} */

/* .cesium-widget, .cesium-widget canvas{
    scale: 1.1 !important;
} */