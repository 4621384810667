.CommsMainWrapper {
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
}
.commsLabel {
  color: rgb(204, 245, 78);
  position: absolute;
  left: 3%;
  z-index: 999;
  top: -8%;
}
.commsLabelFirst {
color: rgb(204, 245, 78);
position: absolute;
left: 5%;
z-index: 999;
top: -8%;
}
.commsLabelLast {
color: rgb(204, 245, 78);
position: absolute;
left: 1.5%;
z-index: 999;
top: -8%;
}
.commsLabelSecondLast {
color: rgb(204, 245, 78);
position: absolute;
left: 3%;
z-index: 999;
top: -8%;
}
/* COMMS CONFIG CSS  START*/
.commsConfigMainWrapper{
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
}
.commsConfigPowerWrapper{
  background: rgba(255, 255, 255, 0.05);
}
.remove-bottom-border-config td{
  border-color: rgba(255, 255, 255, 0.05) !important;
  padding: 5px 5px 10px 5px  !important;
  border: none !important;
  font-family: Montserrat !important;
  font-size: 0.85rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: -0.01125rem !important;
  text-transform: capitalize !important;
  opacity: 1 !important;
}
.remove-bottom-border-config-th th{
  padding: 10px 10px 10px 5px  !important;
  color: #FFF !important;
  font-family: Montserrat !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: -0.015rem !important;}
.commsConfigMainTitle {
  font-family: 1.25rem;
  font-weight: 500;
}

.commsConfigSubTitle {
  font-size: 1rem;
  font-weight: 500;
}
.commsConfigButonWrapper {
  height: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
}
.commsConfigButtons {
  border: 1px solid grey;
  height: 30%;
  padding: 15px 10px 0px 10px;
  color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}
/* comms config css end */
.commsCountWrapper {
  box-sizing: border-box;
  height: 110px;
  border: 1px solid #ccf54e;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
  opacity: 0.8;
  margin: 5px;
}

.commsCountValue{
    font-size: 3rem;
    font-weight: 500;
    color: #F0F0F0,
}
.commsCountKey{
    font-size: 0.85rem;
    font-weight: 400;
    color: #F0F0F0
}
