.GroundStationCreationHeader {
    display: flex;
    padding: 8pt 4pt 8pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.02);
}

.svgContainerGS {
    height: 16pt;
    width: 16pt;
}

.headerText {
    color: rgba(240, 240, 240, 0.69);
    font-family: Montserrat;
    font-size: 11pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.165pt;
}

.headerSubText {
    color: #8C8C8C;
    font-family: Montserrat;
    font-size: 10pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.15pt;
    opacity: 0.5;
}

.creationState {
    display: flex;
    align-items: center;
    gap: 4pt;
}

.addIcon {
    cursor: pointer;
}

.groundStationCreationBody {
    display: flex;
    flex-direction: column;
    padding: 10px 6px;
    gap: 4px;
    flex: 1 0 0;
    overflow-y: auto;
    height: 21vh;
    justify-content: flex-start;
}

.bodyText {
    color: #FFF;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
    opacity: 0.3;
}

.create_gs_alignment {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
        color: #FFF;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: italic;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.18pt;
        opacity: 0.3;
    }
}