.timelineEventCell {
  height: auto;
  position: fixed;
  top:  calc( 10% + 60px );
  left: calc( 50% - 30px );
  float: left;
  display: block;
  width: 240px;
  border-radius: 2px;
  border: 0.5px solid #ccf54e;
  background: var(--dark-background, #101923);
  transition: 300ms;
}

.sidebarOpenTimeline{
  left: 780px;
}

.timelineEventHeader {
  padding: 4px 8px 4px 12px;
  width: 100%;
  border-bottom: 0.5px solid #f0f0f0;
  height: 35px;
}

.timelineEventHeader button {
  background: transparent;
  float: right;
}

.timelineEventHeaderTitle {
  color: #c6fe02;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.48px;
  float: left;
  position: relative;
}

.timelineEventHeaderTitle span {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
}

.timelineEventContent {
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 30px;
}

.timelineEventContentText {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    width: 40%;
    float: left;
    font-weight: 400;
    line-height: normal;
}

.timelineEventInput {
  display: flex;
  width: 120px;
  height: 18px;
  padding: 0px 6px;
  align-items: center;
  border-bottom: 0.5px solid #2465b2;
  background: rgba(240, 240, 240, 0.1);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
