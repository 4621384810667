.slider_header {
    width: 100%;
    height: 10%;
    box-shadow: 0px 10px 30px rgb(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.slider_header__btn {
    transition : all 0.3s ease;
    background: rgba(240, 240, 240, 0.05);
    border-radius: 5px;
    border: 0.1px solid rgba(240, 240, 240, 0.1);
    box-shadow: 0px 0px 2px 0px rgba(188, 188, 188, 0.2);
    backdrop-filter: blur(125px);
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbb;
    .slider_header__btn__icon {
        display: none;
        padding: 7px;
        background: rgba(240, 240, 240, 0.073);
        border-radius: 100%;
        color: rgba(255, 255, 255, 0.4)
    }
}
.slider_header__btn:hover {
    justify-content: space-between;
    border: 0.1px solid rgba(230, 223, 132, 0.3);
    background: rgba(240, 240, 240, 0.07);
    .slider_header__btn__icon {
        display: flex;
    }
}

.slider_caontainer {
    width: 100%;
    height: 90%;
    padding: 20px 10px 0px 10px;    
}

.side_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 100%;
    transition : all 1s ease;
    transform : translate(-95%);
    background: rgb(13, 13, 14);
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    box-shadow: 0px 0px 2px 0px rgba(188, 188, 188, 0.2);
    backdrop-filter: blur(125px);
    z-index: 1;
    .slider_caontainer{
        overflow: hidden;
    }
}

.close__side_bar_icon {
    position: absolute;
    top: 0;
    right: -45px;
    margin: 20px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    /* background: rgba(44, 36, 36, 0.8); */
    transition: all 1s ease;
    z-index: 10;
    display: none;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 0px 15px 35px rgb(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.close__side_bar_icon:hover {
    background: rgb(45, 37, 37);
}

.side_bar:hover {
    transform : translate(0);
    .close__side_bar_icon{
        display: flex;
    }
}
.show_side_bar {
    transform : translate(0);
    .close__side_bar_icon{
        transform: rotate(180deg)
    };
    .slider_caontainer{
        overflow-y: scroll;
    }
}

.slider_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;
    border-radius: 4px;
}


.slider_content_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.03);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px rgba(240, 240, 240, 0.03);
    cursor: pointer;
    transition : all 300ms ease;
}
.header_text{
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}


.slider_content_body{
    width: 100%;
    display: none;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.02);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition : all 300ms ease;
}
.slider_content_body span{
    padding: 10px 10px 10px 50px;
}
.slider_content_body span:hover{
    cursor: pointer;
    background-color: rgba(240, 240, 240, 0.05)
}

.slider_content_header .name_section{
    display: flex;
    width: 100%;
}
.slider_content_header .btn_action_section{
    display: none;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.slider_content_header .btn_action_section.active{
    display: flex
}

.slider_content:hover{
    background: rgba(136, 120, 120, 0.145);
    border-left: 3px solid #CCF54E;
    .slider_content_header .name_section{
        display: flex;
        width: 70%;
    };
    .slider_content_header .btn_action_section{
        display: flex;
    }
}
.slider_content_header .slider_content_body__icon{
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}
.slider_content_header .delete_icon,
.slider_content_header .edit_icon{
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}
.slider_content_header .delete_icon:hover,
.slider_content_header .edit_icon:hover{
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.slider_content.active{
    background: rgba(98, 85, 85, 0.253);
    border-left: 4px solid #CCF54E;
    .slider_content_header .slider_content_body__icon{
        font-size: 18px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        transform: rotate(90deg);
    };
    .slider_content_body{
        display: flex;
    }
}