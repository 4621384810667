.monitor__container__wrapper{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: center;
    background: #191A1A;
    backdrop-filter: blur(15px);
}
.monitor__container__wrapper >
.monitor__container{
    height: calc(100vh - 120px);
    width: 100%;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    color: white;
}
.monitor__container >
.monitor__container__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.monitor__container__header >
.monitor__header__left__container,
.monitor__header__right__container{
    display: flex;
    align-items: center;
    gap: 12px;
}
.monitor__header__left__container >
.button-wrapper >
.button{
    display: flex;
    padding: var(--8, 8px) 12px;
    align-items: center;
    min-width: 100px;
    justify-content: center;
    border-radius: var(--4, 4px);
    background: rgba(240, 240, 240, 0.05);
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
    transition: all 0.1s ease;
    &:hover{
        border: 0.5px solid rgba(204, 245, 78, 0.60);
    }
    &.active{
        border: 0.5px solid rgba(204, 245, 78, 0.60);
        background: rgba(204, 245, 78, 0.10);
        color: #CCF54E;
    }
    &:disabled{
        opacity: 0.5;
        pointer-events: none;
    }
    &.hide_btn{
        display: none;
    }
}

.button-wrapper.disabled {
    cursor: not-allowed; /* Show the disabled cursor */
}
.monitor__header__right__container{
    gap: 5px;
    .button{
        display: flex;
        align-items: center;
        padding-bottom: 3px;
        justify-content: center;
        border-radius: var(--4, 4px);
        opacity: 0.6;
        background: transparent;
        transition: all 0.1s ease;
        &.active{
            border-radius: var(--4, 4px);
            border: 0.5px solid #CCF54E;
            opacity: 1;
            svg{
                stroke: #CCF54E;
            }
        };
        &:disabled{
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    };
    .search__container {
        display: flex;
        width: 200px;
        height: 30px;
        padding: var(--8, 8px) var(--12, 12px);
        align-items: center;
        margin-left: 5px;
        border-radius: var(--4, 4px);
        border: 1px solid rgba(240, 240, 240, 0.20);
        background: #191A1A;
        gap: 8px;
        .input__container{
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;
            input{
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                background: transparent;
                font-size: 12px;
                &::placeholder{
                    color: rgba(255, 255, 255, 0.5);
                }
                &:focus{
                    border: 0;
                    outline: none;
                }
            }
        }
        &.disabled{
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
    }
}

.monitor__container >
.monitor__container__body{
    height: calc(100vh - 175px);
    width: 100%;
    padding-top: 10px;
    overflow-y: auto;
    scrollbar-width: none;
    background: rgba(17, 18, 23, 0.4);
}