.reportDiv {
  height: 70vh;
  width: 100%;
  padding: 0px 12px 33px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: #20563d;
  scrollbar-width: thin;
}
.reportDiv::-webkit-scrollbar {
  width: 4px;
}

.reportDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.reportDiv::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.reportTitleDiv {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding-bottom: 12px;
  height: 20px;
}

.reportTitle {
  width: 50%;
  float: left;
}

.reportDate {
  width: 50%;
  float: right;
  text-align: right;
}

.reportText {
  color: rgba(240, 240, 240, 0.65);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 8px 0px;
}

.reportRecommendationsBox {
  padding: 12px;
  margin-bottom: 20px !important;
}

.reportDataBox {
  border-radius: 8px;
  border: 0.5px solid #827aa9;
  background: #0f0f0f;
  padding: 12px;
  margin: 5px;
  overflow: hidden;
}

.reportDataBoxText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 26px;
  width: 70%;
  float: left;
}

.reportDataBoxData {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 26px;
  width: 30%;
  float: left;
}

.reportCheckBoxDiv {
  margin: 0px !important;
  z-index: 1;
}

.reportOutputDiv {
  transition: transform 0.3s ease-in-out;
  transform: translateY(-25%);
  z-index: -10;
  opacity: 0;
}

.reportOutputDiv.reportOutputDivVisible {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.reportCommentBtn {
  float: right;
  background-color: transparent;
  position: absolute;
  right: 25px;
  transition: 300ms;
}

.reportTagBtn {
  right: 0px;
}

.reportCommentBtn:hover {
  scale: 1.5;
}

.reportDataBoxHeaderText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  width: 100%;
  margin-bottom: 12px;
  text-align: left;
}

.reportDataBoxHalfBox {
  width: 50%;
  float: left;
  margin-bottom: 12px;
}

.reportDataBoxHalfBox.reportDataBoxHalfBoxLeft {
  padding-right: 10px;
  border-right: 1px solid #f0f0f0;
}

.reportDataBoxHalfBox.reportDataBoxHalfBoxRight {
  padding-left: 10px;
}

.reportDataHalfBoxBold {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #f0f0f0;
  width: 100%;
}

.reportDataHalfBoxNormal {
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: #d0d0d0;
  width: 100%;
}

.reportDataBoxRoundIcon {
  background: rgba(240, 240, 240, 0.15);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: left;
  transition: 300ms;
}

.reportDataBoxRoundIcon svg {
  position: relative;
  top: 0.5px;
  right: 2px;
}

.reportDataBoxRoundIcon:hover {
  scale: 1.5;
}

.reportDataBoxAddRecommendationBtn {
  background-color: transparent;
  color: #ccf54e;
  float: right;
  width: calc(100%-20px);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  height: 20px;
  transition: 300ms;
}

.reportDataBoxAddRecommendationBtn:hover {
  scale: 1.1;
}

.reportDotButtonsDiv {
  text-align: center;
  margin: 5px 0px;
}

.reportDotButtons {
  height: 12px;
  border-radius: 6px;
  opacity: 0.3;
  text-align: center;
  margin: 5px;
  transition: 300ms;
}

.reportDotButtons:hover {
  scale: 1.3;
}

.reportDotButtons.active {
  opacity: 1;
  background-color: #f0f0f0;
}

.carousel .control-dots {
  top: 150px !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.reportConfigDiv {
  padding: 12px 0px 0px;
}

.reportConfigHeader {
  color: #36f097;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 12px;
}

.reportConfigHeader.white {
  color: #f0f0f0;
  width: 50%;
  display: inline-block;
}

.reportConfigDropdown {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 50%;
  background-color: transparent;
  border: 0px;
  text-align: center;
}

.reportConfigDropdown.reportAnalysisDropdown {
  width: 100%;
  color: #f2a93b;
  padding: 2px;
  border-right: 4px solid transparent;
}

.selectBorderDiv {
  width: 50%;
  float: right;
  border-radius: 4px;
  border: 1px solid rgba(240, 240, 240, 0.2);
}

.reportConfigDropdown option {
  background-color: rgb(15, 15, 15);
  color: #f0f0f0;
}

.reportConfigItem {
  display: flex;
}

.reportConfigIcon {
  padding: 0px 12px;
}

.reportConfigItemName {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
}

.reportBarGraph {
  font-family: Montserrat;
  /* height: 300px; */
  background: var(--gray-10, #2e2e33);
  border-radius: 8px;
  margin-bottom: 5px;
}

.reportBarGraph.planeBarGraph {
  margin-top: 12px;
}

.reportDataValuesDiv {
  border: 0.5px solid #827aa9;
  padding: 12px;
  border-radius: 8px;
}

.reportDataValuesLabel {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 0.9rem;
  text-align: left;
  float: left;
  gap: 0.75rem;
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
}

.reportDataValuesDivNum {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 0.9rem; 
   text-align: right;
   font-weight: 400;
   gap: 0.75rem;
   padding: 0.5rem;
}

.reportCommentsHeader {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  padding: 12px 0px;
}

.reportCommentsInput {
  border: 0.5px solid #2465b2;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  color: rgba(240, 240, 240, 0.6);
  font-family: Montserrat;
  font-size: 12px;
  height: 56px;
  width: 100%;
}

.reportCommentsInput::placeholder {
  text-align: left;
}

.reportConfigSubItem {
  padding-left: 24px;
}

.reportAnalysisItem {
  display: block;
}

.reportAnalysisIcon {
  padding: 0px 12px;
  width: 40px;
  float: left;
}

.reportAnalysisIcon img {
  width: 14px;
}

.reportAnalysisItemName {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  float: left;
  width: 80%;
}

.planeItem {
  padding-left: 27px;
}

.satelliteItem {
  padding-left: 27px;
}

.mapAnalysisInputsDiv {
  overflow: hidden;
}

.reportPlaneDiv {
  border-radius: 8px;
  /* border: 0.5px solid #827aa9; */
  padding: 5px;
  margin-bottom: 10px;
}

.reportAnalysisItemSeparator {
  margin-bottom: 12px;
}

.reportConfigHeader.white.analysisPlaneHeader {
  width: calc(50% - 35px);
}

.modalCheckboxDiv.modalCheckboxDivNoMargin {
  padding-top: 0px;
  height: 25px;
}

.legendDiv {
  border-radius: 8px;
  border: 0.5px solid #827aa9;
  background: #0f0f0f;
  padding: 12px;
  margin: 12px 0px;
  height: 72px;
}

.legendItem {
  width: 50%;
  float: left;
  height: 30px;
}

.legendColor {
  float: left;
  width: 6px;
  height: 12px;
  border-radius: 25px;
  padding-right: 10px;
}

.legendLabel {
  float: right;
  width: calc(100% - 16px);
  color: rgba(240, 240, 240, 0.65);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.zoomContainer {
  position: relative;
  overflow: hidden;
  cursor: pointer; 
}

.zoomedImage {
  transition: transform 0.3s ease;
}

.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupImage {
  transform-origin: center center;
  transform: scale(1.2);
  transition: transform 0.3s ease;
}