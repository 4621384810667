.constellation_tree_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
}

.tree_constellation_tree_main_header{
    width: 100%;
    display: flex;
    height: 35px;
    padding: 6px 0px 6px 10px;
    align-items: center;
    align-self: stretch;
    position: relative;
}
.tree_constellation_tree_main_header.active,
.tree_constellation_tree_main_header:hover {
    background: rgb(240, 245, 78, 0.1);
    border-radius: 4px;
    .right_header_section .right_header_button{
        display: flex;
    }
}


.left_heder_section{
    display: flex;
    align-items: center;
    gap: 6px;
    width: 85%;
    font-size: 14px;
}
.tree_constellation_tree_main_header.rename{
    .left_heder_section{
        width: 95%;
    };
    .right_header_section{
        display: none;
    }
}

.right_header_section{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    width: 15%;
    height: 100%;
    font-size: 14px;
}

.left_heder_section_title{
    width: 75%;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    color: #F0F0F0;
    font-family: Montserrat;
    line-height: normal;
    letter-spacing: -0.18pt;
}

.left_header_button{
    transform: rotate(-90deg);
    transition: all 0.3s ease;
}

.constellation_tree_main.active{
    .left_header_button {
        transform: rotate(0deg);
    }
    .tree_constellation_tree_main_body{
        display: flex; 
    }
}

.right_header_spinner {
    padding-right: 10px;
}

.right_header_button{
    display: none;
    gap: 12px;
    align-items: center;
}

.tree_constellation_tree_main_body{
    display: none;
    flex-direction: column;
    gap: 2pt;
    padding: 0pt 4pt 0pt 15pt;
    width: 100%;
}

.tree_constellation_tree_main_body_item{
    width: 100%;
    display: flex;
    height: var(--24, 24pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 4pt;
    span {
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
        color: #F0F0F0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.18pt;
        width: 90%;
        cursor: pointer;
    }
}

.disableview {
    pointer-events: none;
}