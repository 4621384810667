.gs_report_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.gs_sat_gs_alignment {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
        width: 40%;
    }
}

.modal_alignment_dropdown {
    width: 60%;
    display: flex;
    flex-direction: row;
}

.groundcontact_section_container {
    display: flex;
    flex-direction: column;
    height: auto; /* Height will aoutomatically adjust when the section will close */
    width: 100%;
    margin-bottom: 5px; /* Showing some space between i/p and o/p section */
}

.groundcontact_output_section_container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: calc(100vh - 370px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
    white-space: nowrap;
}