.mission__model__sidebar{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  background: #0D0D0E;
  box-shadow: 10px 0px 106px 0px rgba(188, 188, 188, 0.25);
  backdrop-filter: blur(25px);
  height: 93vh;
  left: 0;
  bottom: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  transform: translate(-290px);
  z-index: 10;

}
.mission__model__sidebar.active{
  transform: translate(0);
}
.mission__model__sidebar__main__header{
  display: flex;
  padding: 30px 24px 12px 16px;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid transparent; /* Set a transparent solid border initially */
  border-image: linear-gradient(to right, #F0F0F0, transparent); /* Adjust the gradient direction and colors as needed */
  border-image-slice: 1; /* Make the entire border a gradient */
  z-index: 999;
}
.mission__model__sidebar_close__btn{
  height: 30px;
  width: 30px;
  position: absolute;
  right: -15px;
  background-color: rgba(188, 188, 188, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 999 !important;
}
.mission__model__sidebar_close__btn:hover{
  transform: scale(1.2);
}
.mission__model__sidebar__second__header{
  display: flex;
  padding: 11px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.mission__details__section{
  display: flex;
  flex-direction: column;
  padding: 5px 12px 20px 12px;
  width: 100%;
}
.second__header__icons__section{
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 5px;
}
.second__header__icons__section__icon{
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px 4px 0px 0px;
  background: transparent;
}
.second__header__icons__section__icon:hover{
  color: #ccf54e;
  border-radius: 4px 4px 0px 0px;
  border-top: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}
.second__header__icons__section__icon.active{
  border-top: 0.5px solid #CCF54E;
  background: rgba(204, 245, 78, 0.10);
}
.second__header__search__section{
  width: 100%;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  background: #202414;
}

.mission__model__sidebar__body__container{
  display: flex;
  padding: 12px;
  padding-top: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.object__details__body__section{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0px 20px !important; */
}

.triangle__toggle__btn{
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 13px solid rgba(240,240,240,0.5);
  transform: rotate(90deg);
  transition: all 300ms ease-in-out;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  margin: 0;
}
.triangle__toggle__btn:hover,
.triangle__toggle__btn.expanded{
  transform: rotate(180deg);
  border-bottom: 13px solid #F0F0F0;
  opacity: 0.8;
}

.mission__model__sidebar__body__container .details,
.object__details__body__section .details{
  width: 100% !important;
  padding: 3px 0px;
  position: relative;
}


.mission__model__sidebar__body__container .details .summary .summary__object__name,
.object__details__body__section .details .summary .summary__object__name{
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  color: rgba(240,240,240,0.5) !important;
  width: 90% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mission__model__sidebar__body__container .details .summary .summary__object__name.active,
.object__details__body__section .details .summary .summary__object__name.active,
.mission__model__sidebar__body__container .details .summary .summary__object__name:hover,
.object__details__body__section .details .summary .summary__object__name:hover{
  color: #F0F0F0 !important;
}

.mission__model__sidebar__body__container .details .summary,
.object__details__body__section .details .summary{
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mission__model__sidebar__body__container .details.expanded,
.object__details__body__section .details.expanded,
.mission__model__sidebar__body__container .details:hover,
.object__details__body__section .details:hover{
  .space__object__action_btns{
      display: flex !important;
  }
}

.space__object__action_btns{
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  display: none !important;

}
.custom-button-group {
  display: flex;
  gap: 10px;
}

.custom-button {
  background: linear-gradient(180deg, #000000 0%, #1a1a1a 100%);
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.custom-button:hover {
  background: linear-gradient(180deg, #1a1a1a 0%, #000000 100%);
}
.transparent-mode .dropdown-menu.show{
  background-color: #CCF54E !important;
}

