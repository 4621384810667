.circular__progress__container{
    display: flex;
    height: 55px;
    width: 60px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.circular__progress__container .circular__progress{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.circular__progress__container span{
    color: rgba(240, 240, 240, 0.80);
    text-align: right;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}