.bottomNavBar {
  position: fixed;
  bottom: 0;
  left: 25%;
  width: 50%;
}

.upperBar {
  position: relative;
  width: 100%;
  margin: auto;
  height: 30px;
  border-radius: 8px;
  background: #0f0f0f;
}

.upperBarElement {
  margin: auto;
  float: left;
  display: block;
  font-size: 11px;
  height: 100%;
  text-align: center;
  background: rgba(240, 240, 240, 0.1);
  transition: 300ms;

  /* border-right: 0.01px solid white; */
}

.upperBarElement:hover {
  background: rgba(240, 240, 240, 0.3);
}

.upperBarElement svg {
  height: 100%;
}

.upperBarElement span {
  position: relative;
  padding-left: 10px;
}

.activeBottomBarBtn,
.activeBottomBarBtn:hover {
  background: #f0f0f0;
  color: #0f0f0f;
}

.active-img {
  filter: invert(0%) sepia(32%) saturate(4262%) hue-rotate(24deg)
    brightness(119%) contrast(92%);
}

.active-upperbar-img {
  filter: invert(100%) sepia(13%) saturate(124%) hue-rotate(88deg)
    brightness(100%) contrast(92%);
}

.activeTopBarBtn svg {
  opacity: 1 !important;
}

.bottomBar {
  position: relative;
  width: 80%;
  height: 64px;
  border-radius: 8px;
  background: #0f0f0f;
  box-shadow: 0px 0px 40px 0px rgba(131, 131, 131, 0.3);
  margin: 0px auto 20px;
  border-top: 1px solid #f0f0f0;
}

.bottomBarBtn {
  height: 40px;
  width: 10%;
  display: inline-block;
  background: #0f0f0f;
  transition: 300ms;
}

#satelliteLowerbar,
#groundStationLowerbar,
#aoiLowerbar,
#coverageAnalysisLowerbar,
#eclipseAnalysisLowerbar,
#deorbitingAnalysisLowerbar,
#groundStationAccessAnalysisLowerbar {
  margin: auto 2%;
}

#powerAnalysisLowFiLowerbar,
#powerAnalysisHiFiLowerbar,
#thermalAnalysisLowerbar{
  margin: auto 2%;
}

#dataAnalysisLowerbar {
  margin: auto 2%;
}

#keepInKeepOutLowerbar, #adcsSelectionsLowerbar{
  margin: auto 2%;
}

.bottomBarBtn:hover {
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}

.bottomBarBtn svg {
  opacity: 1;
}

.bottomBarBtnDivSection {
  float: left;
  display: flex;
}

.bottomBarBtnDiv {
  margin: auto;
  width: 80%;
  position: relative;
  top: 15%;
  text-align: center;
}

.bottomBarSeparator {
  /* border-right: 0.5px solid #f0f0f0; */
}

.activeTopBarBtn,
.activeTopBarBtn:hover {
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  background: rgba(204, 245, 78, 0.1);
}

#orbital {
  width: 15%;
}

#power {
  width: 25%;
}

#dataFlow {
  width: 15.5%;
}

#adc {
  width: 12%;
}

#navigation {
  width: 20%;
}

#view {
  width: 12.5%;
}

.positionSvgGroundStn {
  transform: translate(40%, 50%) scale(1.5, 2);
}

.tooltipDiv {
  position: relative;
}

.tooltipContent {
  visibility: hidden;
  width: 120px;
  color: #ccf54e;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0;
  transition: opacity 750ms;
  border-radius: 4px;
  background: rgba(39, 51, 75, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7.5px);
}

.tooltipDiv:hover .tooltipContent {
  visibility: visible;
  opacity: 1;
}

.tooltipDiv .tooltipContent::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
