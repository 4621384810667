.modalHeader {
  display: flex;
  padding: 4pt 4pt 4pt var(--12, 12pt);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--none, 0pt) 8pt var(--none, 0pt) var(--none, 0pt);
  border-bottom: 0.5pt solid #F0F0F0;
  background: rgba(255, 255, 255, 0.00);
}



.svgContainer{
    height: 15pt;
    width: 15pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.svgContainerHeader{
    width: 20pt;
    height: 20pt;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal_header_text{
    color: var(--Primary_1, #CCF54E);
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48pt;
}
.icon_title_alignment{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectionComponentBox{
    display: flex;
    padding: 24pt 4pt;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.00);
    border-bottom: 0.5pt solid #F0F0F0;
    border-radius: var(--none, 0pt) 8pt var(--none, 0pt) var(--none, 0pt);
}

.component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4pt;
    flex: 1 0 0;
}

.componentSvgContainer{
    display: flex;
    width: 46pt;
    height: 46pt;
    padding: 8pt;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 8pt;
    border-radius: 4pt;
    box-shadow: 0pt 4pt 4pt 0pt rgba(0, 0, 0, 0.25);
    opacity: 0.6;
    &:hover{
        background: #262B1A;
        border: 0.5pt solid #71804F;
    }
}

.componentTextStyle{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: 21pt; /* 175% */
}

.deactive_text{
    font-weight: 400;
    opacity: 0.6;
}

.active_component{
    border: 0.5pt solid #71804F;
    opacity: 1;
    background: #262B1A;
}

.modal_saperator{
    display: flex;
    padding: 8pt var(--12, 12pt);
    gap: 3pt;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.05);
}

.saperator_header{
    color: #F0F0F0;
    text-align: center;
    font-family: Montserrat;
    font-size: 13pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}