.scenario_timeline_container{
    border-radius: 12px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 0.40);
    box-shadow: 0px 0px 20px 0px rgba(43, 43, 43, 0.30);
    backdrop-filter: blur(125px);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
    padding: 10px 0px 0px 0px;
}
.scenario_timeline_container__wrapper{
    border-radius: 4px;
    background: rgba(36, 101, 178, 0.05);
    padding: 10px;
    display: flex;
    overflow: hidden;
    position: absolute;
    bottom: 5px;
    height: 140px;
    white-space: nowrap;
    width: 100%;
}

.scenario_timeline_scroll_container{
    height: 100%;
    width: 100%;
    position: relative;
    padding-left: 5px;
    gap: 20px;
    overflow-x: auto;
    justify-content: end;
    flex-direction: column;
}
.scenario_timeline_scroll_container::-webkit-scrollbar {
    display: none;
}

.scenario_timeline_task{
    display: flex;
    padding: 6px 0px;
    height: 35px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 0.5px solid #2465B2;
    background: rgba(36, 101, 178, 0.10);
    position: absolute;
    top: 10px;
    cursor: pointer;
}
.scenario_timeline_task.active{
    border-radius: 4px;
    border: 0.5px solid rgb(204, 245, 78, 0.8);
    background: rgba(36, 101, 178, 0.10);
}

.scenario_timeline_task_overview{
    display: flex !important;
    padding: 15px;
    border-radius: 8px;
    border: 0.5px solid rgb(204, 245, 78, 0.8);
    background: rgba(15, 15, 15, 0.69);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    position: absolute !important;
    margin-bottom: 2%;
    width: 420px !important;
    max-height: 150px !important;
    display: inline-flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 12px !important;
    z-index: 999 !important;
}

.scenario_task_info{
    display: flex;
    flex-direction: column;
    color: #F0F0F0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 190px;
    align-items: start;
    justify-content: start;
}

.cinflict-resolve-btn{
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.20);
}

.schedule__info__badge{
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 4px 5px 4px 12px;
    opacity: 10%;
    border-radius: 6px;
    color: #F0F0F0;
    opacity: 0.8;
    gap: 5px;
}
.schedule{
    border: 0.5px solid #2465B2;
    background: rgba(36, 101, 178, 0.10);
    .count{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        padding: 3px 10px;
        background: rgb(36, 101, 178, 0.8);
        min-width: 30px;
        border-radius: 4px;
    }
}
.not_schedule{
    border: 0.5px solid #A6A6A6;
    background: rgba(175, 175, 175, 0.10);
    .count{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        padding: 3px 10px;
        min-width: 30px;
        background: rgb(79, 79, 79, 0.8);
        border-radius: 4px;
    }
    
}
.conflict{
    border: 0.5px solid #F2A93B;
    background: rgba(242, 169, 59, 0.10);
    .count{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        padding: 3px 10px;
        min-width: 30px;
        background: rgb(242, 169, 59, 0.6);
        border-radius: 4px;
    }
    .count_gray{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        padding: 3px 10px;
        min-width: 30px;
        background: rgb(79, 79, 79, 0.8);
        border-radius: 4px;
    }
}
.conflict_gray{
    border: 0.5px solid rgb(79, 79, 79);
    background: rgba(175, 175, 175, 0.10);
    .count{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        padding: 3px 10px;
        min-width: 30px;
        background: rgb(79, 79, 79, 0.4);
        border-radius: 4px;
    }
}

.view__details__btn{
    background: transparent;
    padding: 6px 18px;
    border-radius: 4px;
    border: 0.5px solid #f0f0f08a;
    font-size: 12px;
    &.active{
        color: #ccf54e;
        border: 1px solid #ccf54e;
    }
    &:disabled{
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
}

.left_slider_thumb{
    height: 100%;
    padding-right: 0.3rem;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 0;
}
.right_slider_thumb{
    height: 100%;
    padding-left: 0.3rem;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 0;
}

.disabledViewDetailButton {
    opacity: 0.5; 
    pointer-events: none;
}
  
.draggable_move {
    cursor: grab;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
