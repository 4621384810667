.mainModal {
  width: 325px;
  position: fixed;
  top: 90px;
  left: 60px;
  float: left;
  border-radius: 0px 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.2);
  background: rgba(15, 15, 15, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
  backdrop-filter: blur(125px);
  transition: 300ms;
}

.largerMainModal {
  width: 484px;
}

.sidebarOpenModal {
  left: 320px;
}

.modalHeader {
  display: block;
  width: 100%;
  height: 40px;
  padding: 4px;
  border-radius: 0px 8px 0px 0px;
  background: rgba(255, 255, 255, 0.1);
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #fff, #000) 1;
}

.modalHeaderImg {
  height: 32px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}

.modalHeader svg {
  width: 100%;
  height: 16px;
}

.modalHeader span {
  padding-left: 6px;
  height: 100%;
  float: left;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.56px;
  line-height: 2;
}

.modalCloseBtn {
  float: right;
  padding-right: 12px;
  padding-top: 0px;
  background: transparent;
  height: 32px;
}

.modalComponentBox {
  display: flex;
  padding: 6px 16px 12px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.modalComponentItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 33%;
  position: relative;
}

.modalComponentTwoLineItem {
  height: 100px;
}

.modalTwoComponentItem {
  width: 50%;
}

.modalComponentBtnBox {
  width: 100%;
  margin-bottom: 16px;
}

.modalComponentBtn {
  padding: 8px;
  height: 56px;
  margin: 0px 26px;
  width: 56px;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  background: transparent;
  border-radius: 4px;
}

.modalComponentTwoLineBtn {
  position: absolute;
  top: 10px;
}

.modalTwoComponentBtn {
  margin: 0px 50px;
}

.modalComponentBtn:hover {
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}

.dropdownSpacer {
  margin: 12px;
}

.activeModalComponentBtn {
  background: #262b1a !important;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.activeModalComponentBtn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 0.5px;
  background: linear-gradient(135deg, rgba(113, 128, 79, 1), rgba(240, 240, 240, 0));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.modalComponentTxt {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 175% */
  position: absolute;
  bottom: -4px;
  text-align: center;
  vertical-align: bottom;
}

.modalComponentTwoLineTxt {
  color: #f0f0f0;
  font-family: Montserrat;
  overflow: hidden;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  position: absolute;
  bottom: -4px;
  text-align: center;
  vertical-align: bottom;
}

.modalSubHeader {
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalCheckboxDiv {
  width: 100%;
  margin: 0px 12px;
  padding: 8px 0px;
  display: block;
  position: relative;
  height: 40px;
  /* box-shadow: 0px 4px 20px 0px rgba(10, 10, 10, 0.3); */
}

.modalCheckboxSubDiv {
  width: 33.33%;
  float: left;
}

.modalBoxShadowDiv {
  box-shadow: 0px 4px 20px 0px rgba(10, 10, 10, 0.3);
}

.modalCheckbox {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 12px;
  background: transparent;
  background-color: transparent;
  border-radius: 3px;
  accent-color: rgba(15, 15, 15, 0.3);
  visibility: hidden;
}

.modalCheckboxText {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  float: left;
}
.modalCheckboxTextRight {
  margin-left: 27px;
}

.disabledBtn {
  pointer-events: none;
  opacity: 0.6;
}

.modalGreenSubHeader {
  color: #33e18f !important;
  font-size: 13px;
}

.topMarginClass {
  margin-top: 12px;
}

.modalWhiteSubHeader {
  color: #f0f0f0;
  font-size: 13px;
}

.modalCheckBoxBtn {
  background-color: transparent;
  position: relative;
  left: -6px;
  float: left;
}

.modalDropDownIconBtn {
  background-color: transparent;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -10px;
}

.modalAnalysisDiv {
  display: flex;
  padding: 8px 12px 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalAnalysisDivLong {
  padding: 8px 12px;
}

.modalAnalysisText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalAnalysisInput {
  display: flex;
  width: 64px;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  border-bottom: 0.5px solid #2465b2;
  background: rgba(240, 240, 240, 0.1);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: background-color 0.5s ease-in-out;
}

.modalAnalysisInput:not(:placeholder-shown) {
  background: rgba(52, 52, 52, 0.15);
}

.modalAnalysisInput.modalAnalysisInputText {
  width: calc(100% - 24px);
  margin: 0px 12px 12px;
  height: 31px;
}

.modalSubComponentBox {
  height: 80px;
}

.modalSubComponentItem {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  height: 50px;
  background: transparent;
  transition-timing-function: linear;
  transition-duration: 0.2s;
}

.modalSubComponentItem:hover {
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}

.activeModalSubComponentItem {
  border-radius: 4px;
  border: 0.5px solid #ccf54e !important;
  background: rgba(204, 245, 78, 0.1) !important;
}

.modalSubComponentBtnBox {
  width: 100%;
  margin-bottom: 16px;
}

.modalSubComponentTxt {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  bottom: 0px;
}

.modalFooter {
  flex: 1 1 auto;
  height: 55px;
  padding: 12px;
  overflow: hidden;
  border-style: solid;
  border-width: 0px;
  border-top-width: 0.1px;
  border-image: linear-gradient(135deg, #fff, #000) 1;
}

.modalFooterclose,
.modalFooterapply {
  padding: 8px 24px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modalFooterapplySatCreation{
  padding: 4px 10px 8px 14px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 12px;
  border-radius: 4px;
  background: #20563d;
  transition: 300ms;
}
.modalFooterclose {
  position: absolute;
  left: 12px;
  background: transparent;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.5);
  transition: 300ms;
}

.modalFooterclose:hover {
  background: rgba(240, 240, 240, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.modalFooterapply {
  position: absolute;
  right: 12px;
  border-radius: 4px;
  background: #20563d;
  transition: 300ms;
}

.modalFooterapply:hover {
  background: #2d7b57;
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.modalFooterapplySatCreation:hover {
  background: #2d7b57;
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.disabledSubComponentBtn {
  cursor: not-allowed;
  pointer-events: none;
}

.disabledSubComponentBtn img {
  opacity: 0.4;
}

.disabledSubComponentBtn .modalSubComponentTxt {
  opacity: 0.4;
}

.modalCreationMessage {
  position: absolute;
  top: 10vh;
  right: 70px;
  height: 36px;
  padding: 8px;
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  gap: 8px;
  color: #ccf54e;
  background: #27334b80;
  text-align: center;
  line-height: 100%;
  backdrop-filter: blur(125px);
}

.modalCreationMessage.sideReportbarOpen {
  right: 320px;
}

.modalCreationMessage svg {
  float: left;
  margin-right: 10px;
}

.modalCreationMessage svg path {
  transform: translate(0%, -10%);
}

.modalrefOrbitDiv {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  align-content: center;
  margin: 0px 12px;
  flex: 1 0 0;
  flex-wrap: wrap;
  border: 0.5px solid #2465b2;
  background: rgba(255, 255, 255, 0.1);
  min-height: 31px;
}

.modalrefOrbitDiv::-webkit-scrollbar {
  height: 3.5px;
}

.modalrefOrbitDiv {
  color: rgba(240, 240, 240, 0.65);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalrefOrbitsBtn {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #f2a93b;
  color: #0a0a0a;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
  cursor: pointer;
}

.modalrefOrbitsBtn.secondLine {
  margin-top: 12px;
}

.modalrefOrbitsCloseBtn {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #f2a93b;
  transition: 300ms;
}

.modalrefOrbitsCloseBtn:hover {
  scale: 1.25;
}

.modalrefOrbitsViewBtn {
  padding: 0px;
  width: 16px;
  transition: 300ms;
}

.modalrefOrbitsViewBtn:hover {
  scale: 1.25;
}

.modalScrollableSection {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #20563d;
  scrollbar-width: thin;
}
.modalScrollableSection::-webkit-scrollbar {
  width: 3px;
}

.modalScrollableSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.modalScrollableSection::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modalAoiBtn {
  background: #8e7dee;
}
.modalGroundStationBlueBtn {
  background: #6cabff;
}

.modalGroundStationBlueCloseBtn {
  background: #6cabff;
}

.modalAoiCloseBtn {
  background: #8e7dee;
}

.modalrefOrbitsSpacer {
  margin-bottom: 6px;
  display: flex;
}

.orbitsBtnSpacer {
  margin-bottom: 6px;
  float: left;
}


.referenceOrbitDiv {
  transition: 300ms;
}

.modalInputFullLen {
  display: flex;
  height: 24px;
  padding: 0px 12px;
  align-items: center;
  align-self: stretch;
  border-bottom: 0.5px solid #2465b2;
  background: rgba(240, 240, 240, 0.1);
  width: calc(100% - 24px);
  margin: 6px 12px;
  color: rgba(240, 240, 240, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: background-color 0.5s ease-in-out;
}

.modalInputFullLen:focus {
  background: rgba(52, 52, 52, 0.15);
  border: 0px;
}

.modalInputFullLen:not(:placeholder-shown) {
  color: #f0f0f0;
  background: rgba(52, 52, 52, 0.15);
}

.modalGroundStationDiv {
  height: 250px;
}


.modalSubHeaderDiv {
  height: 23px;
  padding: 4px 12px;
}

.tableModalOpenModal {
  float: right;
  background-color: transparent;
  padding-right: 25px;
  transition: 400ms;
}

.tableModalOpenModal:hover {
  scale: 1.3;
}

.bottomPad {
  padding-bottom: 12px;
}

.orHorizontalLine {
  width: calc(45% - 12px);
  border-top-color: #f0f0f0 !important;
  float: left;
  border-width: 1px;
}

.orDiv {
  display: block;
  padding: 0px 12px;
  width: 100%;
  overflow: hidden;
}

.orText {
  font-size: 12px;
  float: left;
  width: 18%;
  text-align: center;
  padding: 7px;
}

.bigModalHalfDiv {
  width: 50%;
  padding: 12px;
  float: left;
}

.bigModalHalfDivLabel {
  font-size: 12px;
  float: left;
  line-height: 1.75;
}

.bigModalHalfDivInput {
  float: right;
  width: 72px;
  border: 0px;
  border-bottom: 0.5px solid #2465b2;
  padding: 0px 12px;
  font-family: Montserrat;
  font-size: 12px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(240, 240, 240, 0.5);
}

.modalDiv {
  width: 100%;
  overflow: hidden;
}

.modalSelect {
  float: right;
  width: 108px;
  height: 24px;
  font-size: 12px;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  border: 0px;
  border-bottom: 0.5px solid #2465b2;
  background: rgba(52, 52, 52, 0.15);
}

.modalSelectOption {
  background: rgba(52, 52, 52, 0.15);
  color: #000;
}

.blockDisplay {
  display: block;
}

.modalAnalysisDiv.blockDisplay {
  overflow: hidden;
}

.modalAnalysisText.blockDisplay {
  line-height: 2;
  float: left;
}

.modalAnalysisInput.blockDisplay {
  float: right;
}

.modalSelect.blockDisplay {
  float: right;
}

.inModalTable {
  margin: 0px 12px;
}

th,
tr,
td {
  height: 24px;
}

.inModalTableColumn {
  padding: 0px 4px !important;
  width: 33%;
}

.inModaltableInputElement {
  height: 24px !important;
  width: 80% !important;
  padding: 0px !important;
  margin: 2% 10% !important;
  text-align: center;
}

.modalFlexDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalThirdsDiv {
  display: flex;
  font-size: 12px;
  line-height: 2;
}

.modalThirdsDiv input {
  width: 36px !important;
  height: 24px !important;
  margin: 0px !important;
  margin-left: 12px !important;
}

.geofenceModalContentBox {
  border-radius: 8px;
  background: #0f0f0f;
  padding: 8px 0px;
  position: absolute !important;
  top: 10%;
  left: calc(50% + 120px);
}

.geofenceModalContentBoxBtn{
  background: transparent;
  display: none;
}

.thinHorizontalLine{
  width: 30%;
  padding: 0px 16px;
  height: 1px;
  color: #f0f0f0;
  background-color: #f0f0f0 !important;
}

.react-datetime-picker__wrapper{
  font-size: 12px;
}

.react-datetime-picker__clear-button__icon.react-datetime-picker__button__icon, .react-datetime-picker__calendar-button__icon.react-datetime-picker__button__icon{
  stroke: #f0f0f0 !important;
  width: 12px !important;
}

.react-calendar{
  background: #0f0f0f !important;
  position: fixed;
}

.calendarOpenModal{
  width: 400px !important;
}

.react-calendar__navigation button:hover{
  color: #000 !important;
}

.react-calendar__tile:hover{
  color: #000 !important;
}

.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm option{
  background: #0f0f0f !important;
  color: #f0f0f0 !important;
}

.modalSelect.blockDisplay.eclipseAnalysis option{
  background: #0f0f0f !important;
  color: #f0f0f0 !important;
}