.ground_station_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ground_station_details_container{
    display: flex;
    padding: 6px 0px 6px 12px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    white-space: nowrap;
    flex-shrink: 0;
    height: 35px;
}
.ground_station_details_container.active,
.ground_station_details_container:hover{
    background: rgb(240, 245, 78, 0.1);
}


.selected{
    background: rgba(122, 179, 255, 0.07);
}

.ground_station_left_panel{
    display: flex;
    align-items: center;
    gap: 6px;
    width: 85%;
    font-size: 14px;
    span{
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        color: #F0F0F0;
        font-family: Montserrat;
        line-height: normal;
        letter-spacing: -0.18pt;
        cursor: pointer;
    }
    svg{
        height: 12px;
        width: 12px;
    }
    &.lg{
        width: 95%;
    }
}

.action__menu__container{
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: end;
    gap: 12px;
}
.ground_station_details_container:hover .action__menu__container{
    display: flex;
}

.icon_alignment{
    display: flex;
    width: 16pt;
    height: 16pt;
    justify-content: center;
    align-items: center;
}

.disablling{
    visibility: hidden;
}

.menu_item_001{
    position: absolute;
    display: flex;
    padding: 4pt 0pt;
    align-items: center;
    align-self: stretch;
    gap: 4pt;
    span{
        color: #F0F0F0;
        text-align: center;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.gs_menu_item_container{    
    position: absolute;
    display: inline-flex;
    padding: 8pt 4pt;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    margin: 120pt;
    border-radius: 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.10);
    background: rgba(9, 9, 9, 0.50);
    backdrop-filter: blur(20pt);
    margin: -120pt;
}

.menu_option_item{
    display: flex;
    padding: 4pt 0pt;
    align-items: center;
    align-self: stretch;
    border-radius: 4pt;
    gap: 4pt;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}