
.contact__center__container__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: all 0.3s ease;
    background: #000;
    backdrop-filter: blur(25px);
    transform: translateY(97%);
}
.contact__center__container__wrapper.active{
    transform: translateY(0%);
    background: #000;
    backdrop-filter: blur(25px);
    z-index: 98;
    .container__minimization__button{
        svg{
            transform: rotate(180deg);
        }
    }
}
.contact__center__container__wrapper.disabled{
    transform: translateY(100%);
    .container__minimization__button{
        display: none;
    }
}
.contact__center__container__wrapper:hover{
    z-index: 120;
}

.container__minimization__button{
    display: flex;
    position: absolute;
    top: -17px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 4px 4px;
    background: transparent;
    transform: rotate(0deg);
    transition: all 0.3s ease;
    cursor: pointer;
    width: 50px;
    height: 20px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: rgb(19, 25, 1);;
}
.contact__center__container{
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    transition: all 0.3s ease;
}

.contact__center__container__header{
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(204, 245, 78, 0.09);
    backdrop-filter: blur(25px);
    z-index: 1;
}

.contact__center__header__selector__container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #E3E3E3;
    height: 40px;
    position: relative;
    transition: all 0.3s ease;

    &.select{
        border-radius: 4px;
        border: 1px solid rgba(240, 240, 240, 0.2);
        margin-right: 12px;
        width: 150px;
    }
    .selector__selected{
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0px 12px;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        gap: 8px;
        cursor: pointer;
        .icon{
            transform: rotate(90deg);
            font-size: 18px;
            color: #E3E3E3;
            transition: all 0.3s ease;
        }
    };
    .selector__option__container{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: 0;
        width: 100%;
        max-width: 250px;
        font-size: 14px;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        left: 0px;
        z-index: 999;
    }
}
.contact__center__header__selector__container.active{
    .selector__selected > .icon{
        transform: rotate(180deg)
    }
    .selector__option__container{
        height: auto;
        background: rgba(53, 49, 49, 0.95);
        /* box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10); */
        border: 0.5px solid rgba(240, 240, 240, 0.20) ;
        backdrop-filter: blur(25px);
    }
    .selector__option{
        width: 100%;
        display: flex;
        padding: 8px 25px 8px 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s ease;
        cursor: pointer;
        &.hide_option{
            display: none;
        };
        &:hover{
            background: rgba(240, 240, 240, 0.1);
        };
        &.active{
            background: rgba(240, 240, 240, 0.26);
        };
        &:disabled{
            pointer-events: none;
            cursor: default !important;
            opacity: 0.5;
            user-select: none;
            &:hover{
                background: transparent;
            }
        };
    }

}

.contact__center__header__filter_selector{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 0;
    max-width: 200px;
    min-width: 150px;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    transition: all 0.3s ease;
    scale: 0;
    .filter_selector__option{
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 2px;
        z-index: 999;
        .selected__option{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 8px 8px 12px;
            border-radius: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            gap: 10px;
            cursor: pointer;
            transition: all 0.3s ease;
            .icon{
                transform: rotate(90deg);
                font-size: 18px;
                color: #E3E3E3;
                transition: all 0.3s ease;
            }
            &:hover{
                background: rgba(203, 245, 78, 0.1);
            }
        }
        .filter_selector__inner__option__container{
            display: flex;
            flex-direction: column;
            align-items: start;
            height: 0;
            overflow-y: auto;
            -ms-overflow-style: none; 
            scrollbar-width: none; 
            .filter_selector__inner__option{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                padding: 8px 25px 8px 12px;
                border-radius: 4px;
                cursor: pointer;
                gap: 8px;
                .check__box {
                    border: 1px solid #E3E3E3;
                    background: transparent !important;
                    color: #E3E3E3;
                    padding: 2px;
                    height: 15px;
                    width: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 3px;
                    .check{
                        display: none;
                        font-size: 12px;
                    }
                }
                .option__label{
                    width: 95%;
                    text-align: start;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &:hover{
                    background: rgba(35, 35, 35, 0.5);
                }
                &.active{
                    .check__box > .check{
                        display: block;
                    }
                }
            }
        }
        &.active{
            .selected__option{
                background: rgba(240, 240, 240, 0.26);
                backdrop-filter: blur(25px);
                .icon{
                    transform: rotate(0deg);
                };
            }
            .filter_selector__inner__option__container{
                height: 115px;
            }
        }
    }
} 

.contact__center__header__button{
    background: transparent;
    height: 40px;
    width: 40px;
    display: flex;
    color: #E3E3E3;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s ease;
    border: 1px solid rgba(240, 240, 240, 0.20);
    &:hover{
        background: rgba(240, 240, 240, 0.20);
    }
    &.active{
        background: rgba(240, 240, 240, 0.20);
        .contact__center__header__filter_selector{
            height: auto;
            scale: 1;
            background: rgba(0, 0, 0, 0.90);
            border: 0.5px solid rgba(240, 240, 240, 0.20);
            /* box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10); */
            backdrop-filter: blur(25px);
        }
    }
    &:disabled{
        pointer-events: none;
        cursor: default !important;
        opacity: 0.6;
        user-select: none;
    }
    &:disabled:hover {
        background: transparent;
    }
}

/* // ALERT SECTION STYLE // */
.alert__section__container{
    position: absolute;
    top: -485px;
    left: 10px;
    height: 480px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 300px;
    display: none;
    flex-direction: column;
    justify-content: end;
    padding: 10px;
    gap: 8px;

    &.show__alert{
        display: flex;
    }
}

.alert__card{
    gap: 8px;
    width: 100%;
    padding: 8px;
    display: flex;
    cursor: pointer;
    color: #FFEAEA;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    border-radius: 4px;
    line-height: normal;
    flex-direction: column;
    font-family: Montserrat;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(25px);
    .alert__type__label{
        color: rgba(240, 240, 240, 0.80);
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .badge{
        display: flex;
        padding: 4px var(--8, 8px);
        justify-content: center;
        align-items: center;
        gap: var(--4, 4px);
        border-radius: var(--4, 4px);
        background: rgba(240, 240, 240, 0.10);
    }
    .status__success,
    .status__schedule{
        color: #CCF54E;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .status__error{
        color: rgba(240, 240, 240, 0.80);
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: rgb(217, 54, 54);
    }
    .status__not__schedule{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.status__indicator{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.status__success,
    &.status__schedule{
        background: #CCF54E;
    }
    &.status__error{
        background: rgb(217, 54, 54);
    }
    &.status__not__schedule{
        background: #F0F0F0;
    }
}
.notification__card{
    gap: 8px;
    width: 100%;
    padding: 8px;
    display: flex;
    color: rgba(240, 240, 240, 0.80);
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    border-radius: 4px;
    line-height: normal;
    font-family: Montserrat;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(25px);
    .status__error{
        background: #D93636;
        box-shadow: 0px 4px 8px 0px rgba(160, 160, 160, 0.08);
        backdrop-filter: blur(10px);
        padding: 4px var(--8, 8px);
        border-radius: var(--4, 4px);
    };
    .status__success{
        background: #FFEAEA;
        box-shadow: 0px 4px 8px 0px rgba(160, 160, 160, 0.08);
        backdrop-filter: blur(10px);
        padding: 4px var(--8, 8px);
    }
}

/* // BODY SECTION STYLE // */

/* // BODY SECTION STYLE // */
.contact__center__container__body{
    display: flex;
    width: 100%;
    height: auto;
    flex: 1;
}

.container__loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #000;
    z-index: 999;
}
