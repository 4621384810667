.modal__container__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 115px);
    width: auto;
    position: absolute;
    top: 57px;
    right: 5px;
    scale: 0;
    transform: translate(0px, 0px);
    &.active{
        scale: 1;
        z-index: 99;
        padding: 10px;
    }
    &.current__modal{
        z-index: 111;
    }
}