.input-range{
    display: flex;
    height: 30px;
    align-items: end;
    position: relative;
}

.input-range__label {
    display: none !important;
}

.input-range--disabled .input-range__track {
    background: transparent;
    opacity: 0.5;
    cursor: auto;
    .input-range__slider{
        cursor: auto;
    }
}
.input-range__track {
    height: 5px;
    background: transparent;
    .input-range__track--active{
        height: 1px;
        /* background: linear-gradient(to right,rgba(204, 245, 78, 0.4), rgba(0, 83, 181, 0.4)); */
        background: #ccf54e;
        /* background: rgb(36, 101, 178); */
    }
    .input-range__slider{
        width: 12px;
        height: 12px;
        margin-top: -6.5px;
        margin-left: -0.3rem;
        /* background: linear-gradient(to right,rgba(204, 245, 78, 0.4), rgba(0, 83, 181, 0.4)); */
        background: #ccf54e;
        /* background: rgb(36, 101, 178); */
        cursor: ew-resize;
        border: none;
    }
}
