.feature {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
    color: #CCF54E;
    /* width: 86%; */
}

.drawFeature {
    width: 134px;
    height: Hug (31px)px;
    padding: 8px 20px 8px 20px;
    gap: 12px;
    border-radius: 4px;
    border: 0.5px 0px 0px 0px;
    border: 0.5px dashed #5C7CA2;
    background: #1A20351A;
    color: rgba(245, 245, 245, 0.7);
    margin-left: auto;

    .fe-plus {
        color: rgba(245, 245, 245, 0.7);
    }

}

.uploadFeature {
    width: Hug (108px)px;
    height: Hug (31px)px;
    padding: 8px 20px 8px 20px;
    gap: 12px;
    border-radius: 0px 4px 4px 0px;
    border: 0.5px 0px 0px 0px;
    opacity: 0px;
    border: 0.5px dashed #5C7CA2;
    background: #1A20351A;
    color: rgba(245, 245, 245, 0.7);

    .fe-upload {
        color: rgba(245, 245, 245, 0.7);
    }
}

/* .showing {
    background: rgba(175, 189, 205, 1) !important;
} */

.aoiManager .accordion-button {
    background: rgba(175, 189, 205, 1) !important;

    /* background: rgba(18, 68, 47, 0.10) !important; */
    box-shadow: 0px 4px 12px 0px rgba(10, 10, 10, 0.40) !important;
    padding-left: 30px;
    padding-right: 45px !important;
    border-radius: var(--border-radius-large, 12px) !important;
    /* border: solid black; */
    /* border-radius: 10px !important; */
    height: 50px;
    transition: 0.5s ease;

    span.color-text {
        color: #000 !important;
    }

    .fe-more-vertical {
        color: #000 !important;
    }

    i {
        color: #000 !important;
    }

}

.accordion-container {
    display: flex;
    flex-direction: column;
    transition: 0.5s ease;
}

.aoiManager .accordion-button.collapsed {
    background: linear-gradient(357.77deg, rgba(12, 113, 69, 0.099) -7%, rgba(52, 106, 217, 0.0999) 57.86%, rgba(3, 19, 52, 0.05) 117.31%) !important;
    /* background: rgba(10, 10, 10, 0.4) !important; */
    padding-left: 30px;
    padding-right: 45px !important;
    border-radius: var(--border-radius-large, 12px) !important;
    height: 50px;
    transition: 0.5s ease;

    span.color-text {
        color: rgba(240, 240, 240, 1) !important;
    }

    .fe-more-vertical {
        color: rgba(240, 240, 240, 1);
    }

    i {
        color: rgba(240, 240, 240, 1) !important;
    }

}

.aoiManager .action-button {
    margin-left: 1220px !important;

}

.aoiManager .accordion-button::after {
    display: none;
}


.transparent-mode .accordionItem {
    margin-top: 7px;
    border: none !important;
}

.aoiManager .transparent-mode .accordion-button:focus {
    height: 50px;
}

.accordionBody {
    margin-left: 24px;
    background: linear-gradient(357.77deg, rgba(12, 113, 69, 0.099) -7%, rgba(52, 106, 217, 0.0999) 57.86%, rgba(3, 19, 52, 0.05) 117.31%) !important;
    border-radius: var(--border-radius-large, 12px) !important;

    svg {
        display: inline-block;
        width: 18px !important;
    }

}

.singleaoi {
    background: linear-gradient(357.77deg, rgba(12, 113, 69, 0.099) -7%, rgba(52, 106, 217, 0.0999) 57.86%, rgba(3, 19, 52, 0.05) 117.31%) !important;
    border-radius: var(--border-radius-large, 12px) !important;
}


.accordian-main-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    margin-top: 5pt;
}

.active-accordian {
    border: 0.5pt solid #4E5E15;
    padding: 4pt;
}

.accordian-header {
    display: flex;
    padding: 12px var(--24, 24px) 12px var(--19, 19px);
    align-items: center;
    gap: 30px;
    align-self: stretch;
    border-radius: var(--12, 12px);
    background: rgba(18, 68, 47, 0.10);
    box-shadow: 0px 4px 12px 0px rgba(10, 10, 10, 0.40);
    backdrop-filter: blur(5px);
    justify-content: space-between;
}

.accordian-text-allignment {
    display: flex;
    flex-direction: row;
}

.acc-header-text {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    margin-left: 5px;
    white-space: normal;
    /* Allows the text to wrap */
    word-break: break-word;
    /* Ensures long words break */
}

.acc-header-description {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    white-space: normal;
    /* Allows the text to wrap */
    word-break: break-word;
    /* Ensures long words break */
}

.accordian-item {
    display: flex;
    justify-content: space-between;
    padding: var(--8, 8px) var(--24, 24px) var(--8, 8px) var(--50, 50px);
    align-items: center;
    width: 100%;
    gap: 30px;
    align-self: stretch;
    border-radius: var(--12, 12px);
    box-shadow: 0px 4px 12px 0px rgba(10, 10, 10, 0.40);
    backdrop-filter: blur(5px);
    /* overflow: hidden; Hide content that exceeds the height */
    max-height: 0;
    /* Start with a height of 0 */
    transition: max-height 0.3s ease-out;
    max-height: 500px;
}

.sub-item {
    display: flex;
    flex-direction: row;
    margin-left: 20pt;
    justify-content: space-between;
}

.single-aoi-header {
    display: flex;
    padding: 12px var(--24, 24px) 12px var(--24, 24px);
    align-items: center;
    gap: 30px;
    align-self: stretch;
    border-radius: var(--12, 12px);
    background: rgba(240, 240, 240, 0.05);
    margin-top: 5pt;
    box-shadow: 0px 4px 12px 0px #0A0A0A66;
backdrop-filter: blur(10px);
}

.single-aoi-alignment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
}

.single-aoi-icon {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.edit_delete_dialog {
    display: flex;
    padding: 10px 8px 8px 8px; 
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 8pt);
    position: absolute;
    right: 25pt;
    top: -35px;
    width: 60px;
    border-radius: 8pt;
    border: 1pt solid #162843;
    background: rgba(7, 22, 51);
    backdrop-filter: blur(7.5px);
    z-index: 999;
    cursor: pointer;
}

.edit_delete_dialog_single {
    padding: 8px;
    text-align: start;
    width: 80px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 8pt);
    position: absolute;
    right: 25pt;
    border-radius: 8pt;
    border: 1pt solid #162843;
    background: rgba(7, 22, 51);
    backdrop-filter: blur(7.5px);
    z-index: 999;
    cursor: pointer;
}

.edit_delete_card_text {
    width: 62px;
    color: #BBBECA;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    height: 17px;
}
.edit_delete_card_text_single {
    height: 37px;
    width: 76px;
    color: #BBBECA;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-content: center;
}

.accordian_delete_avg {
    background: transparent;
    position: relative;
    display: flex;
    width: 30px;
    height: 30px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    z-index: 9999;
    margin-right: 12px;
}