.popup-modal-scenario{
    display: 'none';
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    width: 250px;
    background: rgba(15, 15, 15, 0.69);
    backdrop-filter: blur(125px);
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
}


.labelClass{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400; 
    line-height: normal; 
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}