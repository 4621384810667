.geometry-container{
    width: 320px;
    height: 246px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.65);
    backdrop-filter: blur(10px); /* Adjust the blur value as needed */
    -webkit-backdrop-filter: blur(10px); /* For better compatibility with Safari */
}
.geometry-header{
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* border-bottom: 0.5px solid #F0F0F0; */
    border-bottom: 0.5px solid; /* Set a transparent border to act as a container for the gradient */
border-image: linear-gradient(90deg, rgba(240, 240, 240, 0.2) 3.36%, rgba(240, 240, 240, 0) 120.86%) 1;
}
.popupHeader{
    color:#CCF54E;
    svg{
        margin-right: 4px;
    }
}

.geometry-conf-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.feature-input{
    border: 0.5px solid rgba(240, 240, 240, 0.3);
    background: rgba(10, 10, 10, 0.65);
    input{
        width: 100%;
        background: transparent;
        border: none;
        padding: 5px 10px;
        min-height: 30px;
    }
    textarea{
        width: 100%;
        background: transparent;
        border: none;
        padding: 5px 10px;
        min-height: 60px;
        resize: none;
    }
}

.feature-input input:focus,
.feature-input textarea:focus {
  border-color: rgba(240, 240, 240, 0.3) !important; /* Change border color on focus */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2); /* Optional shadow on focus */
}