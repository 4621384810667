.add_gs_provider{
    background-color: rgba(26, 32, 53, 0.4);
    width: 430px;
    height: 200px;
    padding: 15px 20px;
    cursor: pointer;
    border-radius: 8px;
    border: 2px dashed rgba(190, 199, 216, 0.2);
    display: flex ;
    align-items: center;
    justify-content: center;
    i, .fe {
        color: #cccccc !important;
        opacity: .8;
    };
    &:hover{
    border: 2px dashed rgba(190, 199, 216, 0.2);
    background: rgba(36, 34, 66, 0.61);
    }
}

@media (max-height: 800px) {
    .add_gs_provider{
    width: 380px;
}
}

.transceiver_item_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    border-radius: var(--surface-border-radius---radius-s-md, 12px);
    background: rgba(240, 240, 240, 0.02);
    box-shadow: 0px 4px 12px 0px rgba(10, 10, 10, 0.40);
    backdrop-filter: blur(5px);
    padding: 15px 10px;
}

.modal_devider{
    width: 100%;
    border-bottom: 1px solid transparent; /* Set a transparent solid border initially */
    border-image: linear-gradient(to right, #B7B7B7, transparent); /* Adjust the gradient direction and colors as needed */
    border-image-slice: 1; /* Make the entire border a gradient */
    
}

.gsp_badge_info{
    display: flex;
    padding: 10px 20px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(10, 70, 103, 0.40);
}
.gsp__status__badge{
    display: flex;
    padding: 4px;;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba(242, 169, 59, 0.60);
    font-size: 10px;
    &.configured{
        max-width: 70px;
    };
    &.not_configure{
        max-width: 100px;
    }
}
.gs_config_btn,
.mqtt_config_btn {
    min-width: 124px;
    padding: 7px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 0.5px dashed rgba(255, 255, 255, 0.4);
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(5px);
    gap: 10px;
}

