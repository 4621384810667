.validation__output{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(15px);
    background: linear-gradient(355.7deg, rgba(0, 0, 0, 1.168) -99.65%, rgba(1, 18, 50, 0.4) 132.3%);
    box-shadow: -12px 0px 20px -10px rgba(0, 0, 0, 0.25);
    z-index: 9;
}

.validation__body {
    width: 100%;
    height: 92%;
    position: relative;
}
.validation__footer {
    width: 100%;
    height: 8%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.telecommand-list-container{
    height: 65vh;
    width: 100%;
    overflow-y: auto;
    padding-right: 10px;
}


.footer-btn-list{
    background-color: rgba(255,255,255,0.1);
    border-radius: 4px;
    overflow: hidden;
}
.footer-tab-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 10px 20px;
    color: #F0F0F0;
    font-size: 14px;
    gap: 12px;
    background-color: transparent;
    border-radius: 2px;
    transition: background-color 0.3s, color 0.3s; /* Add a smooth transition */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.footer-tab-btn.active{
    background-color: #CCF54E;
    color: #000000;
}
.rs-picker-menu{
    z-index: 999 !important;
}