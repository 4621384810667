.planning__container__wrapper{
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: end;
    justify-content: center;
    background: #191A1A;
    backdrop-filter: blur(15px);
    padding-bottom: 5px;
}
.planning__container{
    height: calc(100vh - 130px);
    width: 100%;
    display: flex;
    padding: 0px 16px;
}

.planning__container__header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .planning__header__left__container{
        display: flex;
        align-items: center;
        gap: 12px;
        .button{
            display: flex;
            padding: var(--8, 8px) 12px;
            align-items: center;
            min-width: 100px;
            justify-content: center;
            border-radius: var(--4, 4px);
            background: rgba(240, 240, 240, 0.05);
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.8;
            transition: all 0.1s ease;
            &:hover{
                border: 0.5px solid rgba(204, 245, 78, 0.60);
            }
            &.active{
                border: 0.5px solid rgba(204, 245, 78, 0.60);
                background: rgba(204, 245, 78, 0.10);
                color: #CCF54E;
            }
            &:disabled{
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        };
    };

    .operate__planning__header__right__container{
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        button{
            display: flex;
            height: 36px;
            padding: var(--8, 8px) 12px;
            justify-content: center;
            align-items: center;
            gap: var(--8, 8px);
            border-radius: 4px;
            background: rgba(240, 240, 240, 0.10);
            &:hover{
                background: rgba(204, 245, 78, 0.10);
                svg{
                    stroke: #CCF54E;
                    fill: #CCF54E;
                }
            }
            &.active{
                border: 0.5px solid #CCF54E;
                background: rgba(204, 245, 78, 0.10);
                svg{
                    stroke: #CCF54E;
                }
            };
            &:disabled{
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        }
    }
    .planning__header__right__container{
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        .button{
            display: flex;
            align-items: center;
            padding-bottom: 3px;
            justify-content: center;
            border-radius: var(--4, 4px);
            opacity: 0.6;
            background: transparent;
            transition: all 0.1s ease;
            &.active{
                border-radius: var(--4, 4px);
                border: 0.5px solid #CCF54E;
                opacity: 1;
                svg{
                    stroke: #CCF54E;
                }
            };
            &:disabled{
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        };
        display: flex;
        width: 200px;
        height: 30px;
        padding: var(--8, 8px) var(--12, 12px);
        align-items: center;
        margin-left: 5px;
        border-radius: var(--4, 4px);
        border: 1px solid rgba(240, 240, 240, 0.20);
        background: #191A1A;
        gap: 8px;
        .input__container{
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;
            input{
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                background: transparent;
                font-size: 12px;
                &::placeholder{
                    color: rgba(255, 255, 255, 0.5);
                }
                &:focus{
                    border: 0;
                    outline: none;
                }
            }
        }
    }
}
.planning__container__body{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}