.targetNameClass{
    color: 'rgba(204; 245; 78; 1.00)';
    font-family: 'Montserrat';
    font-size: '14px'; 
    font-style: 'normal'; 
    font-weight: '400'; 
    line-height: 'normal'; 
    letter-spacing: '0.56px'

}
.targetLabelClass{
    color: '#F0F0F0'; font-family: 'Montserrat'; font-size: '12px'; font-style: 'normal'; font-weight: '400'; line-height: 'normal'; opacity: '0.6'

}
.latLongClassName{
    color: '#EEE'; font-family: 'Montserrat'; font-size: '10px'; font-style: 'normal'; font-weight: '400'; line-height: 'normal'; opacity: '0.6'

}
.latLongValueClassName{
    color: 'color: #F0F0F0'; font-family: 'Montserrat'; font-size: '12px'; font-style: 'normal'; font-weight: '400'; line-height: 'normal'; letter-spacing: '0.56px'; margin-left: '4px'
}

.labelClass{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400; 
    line-height: normal; 
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}
.popup-modal{
    display: 'none';
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    background: #0B0C0D;
    backdrop-filter: blur(125px);
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
}