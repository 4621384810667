.orbital_body{
    width: 100%;
    display: flex;
    padding: var(--12, 12px) var(--12, 12px) 4px var(--12, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: rgba(204, 245, 78, 0.05);
}

.orbital_body_title{
    color: #8CBA00;
    text-align: center;
    font-family: Montserrat;
    font-size: 11pt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.orbital_body_item{
    display: flex;
    padding-top: 4pt;
    padding-bottom: 4pt;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.rs-stack-item{
    background: black !important;
}

.rs-picker-menu{
  background-color: #292D33 !important;
}
.orbitalDetailsTimePickerContainer .rs-picker-toggle-textbox{
  background: black !important;
}
.rs-calendar-time-dropdown-column-title,
.rs-calendar-btn-close{
  background-color: black !important;
}
.rs-calendar-time-dropdown,
.rs-calendar-month-dropdown{
  background-color: black !important;
  border-top: 1px solid rgba(240, 240, 240, 0.3) !important;
}
.orbitalDetailsTimePickerContainer .rs-picker-toggle-textbox{
  background: black;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color: #F0F0F0 !important;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn{
  display: flex;
  padding: var(--4, 4px) 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--4, 4px);
  border: 0.5px solid rgba(240, 240, 240, 0.3) !important;
  background: rgba(10, 10, 10, 0.65) !important;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 36px;
}

.rs-calendar-time-dropdown-cell:hover,
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: rgba(108, 112, 114, 0.27) !important;
  color: #e3e7ed !important;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn,
.orbitalDetailsFormItem input {
  display: flex;
  padding: var(--4, 4px) 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--4, 4px);
  border: 0.5px solid rgba(240, 240, 240, 0.3) !important;
  background: rgba(10, 10, 10, 0.65) !important;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 36px;
}

.rs-calendar-body{
height: 258px;
}