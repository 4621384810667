.telemetry__panel__container{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    justify-content: space-between;
    height: 100%;
    width: 350px;
    overflow: hidden;
    transition: all 0.3s ease;
    &.expand{
        width: 800px;
        .telemetry__panel__header {
            .panel__colapse__btn{
                transform: rotate(180deg);
            }
        };
    };
    .telemetry__panel__header{
        display: flex;
        padding: var(--8, 8px);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 0.5px solid rgba(240, 240, 240, 0.20);
        .panel__header__title{
            color: #CCF54E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        };
        .panel__close__btn,
        .panel__pin__btn,
        .panel__colapse__btn{
            background: transparent;
            transform: rotate(0deg);
            transition: all 0.3s ease;
            display: flex;
            width: var(--24, 24px);
            height: var(--24, 24px);
            padding: var(--4, 4px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
        };
        .panel__pin__btn.pinned{
            border-radius: var(--4, 4px);
            background: rgba(204, 245, 78, 0.10);
            svg{
                transform: rotate(-45deg);
                stroke: #CCF54E;
                stroke-width: 0.5px;
                transition: all 0.3s ease;
            }
        }
    };
    .telemetry__panel__body__container{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        width: 100%;
        flex: 1;
        background: #191A1A;
        backdrop-filter: blur(15px);
        .telemetry__panel__body__container__header{
            display: flex;
            align-items: start;
            justify-content: space-between;
            width: 100%;
            padding: var(--8, 8px);
            .header__select__container{
                background: rgba(0, 0, 0, 0.20);
                width: 200px;
                border: none;
            }
        }
        .telemetry__panel__body__container__body{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 220px);
            background: transparent;
            padding: 10px;
        }
    }
}
