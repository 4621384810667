.generate__schedule__container{
    display: none;
    width: 361px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--none, 0px) 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 0.69);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    position: absolute;
    z-index: 1000;
    top: 45px;
    right: 0px;
    &.open{
        display: flex;
    }

    .generate__schedule__container__header{
        display: flex;
        padding:  8px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 100%;
        border-bottom: 1px solid transparent; /* Set a transparent solid border initially */
        border-image: linear-gradient(to right, #B7B7B7, transparent); /* Adjust the gradient direction and colors as needed */
        border-image-slice: 1;
        background: rgba(255, 255, 255, 0.00);
        span{
            color: #CCF54E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.64px;
        }
    };
    .generate__schedule__container__footer{
        display: flex;
        padding: 8px 12px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        button{
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;

            &.close{
                border-radius: 4px;
                border: 0.5px solid rgba(204, 245, 78, 0.80);
                background: transparent;
                color: rgba(204, 245, 78, 0.80);
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            };
            &.generate{
                border-radius: 4px;
                background: #58700D;
                color: #F0F0F0;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                &:hover{
                    svg{
                        fill: none
                    }
                }
            }

        }
    }
    .generate__schedule__container__body{
        display: flex;
        padding: var(--8, 8px) 0px;
        flex-direction: column;
        align-items: flex-start;
        background: rgba(204, 245, 78, 0.05);
        width: 100%;
        .badge{
            display: flex;
            padding: var(--4, 4px) var(--8, 8px);
            justify-content: start;
            gap: 12px;
            align-items: center;
            align-self: stretch;
            background: rgba(240, 240, 240, 0.10);  
            color: #FFF;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        };
        .form__container{
            display: flex;
            padding: var(--8, 8px) 12px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            .form__details{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--8, 8px);
                align-self: stretch;
                span{
                    color: #F0F0F0;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .task__plan__select{
                    background: rgba(10, 10, 10, 0.65);
                }
            }
        }
    }
}