/* #backgroundOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 0;
} */

.sidebarReportTitle {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.56px;
  padding: 12px 0px 12px 16px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(45deg, #fff, #000) 1;
}

.sidebarReportNavBtn {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #0f0f0f;
  position: relative;
  transition: 300ms;
}

.sidebarReportNavBtn:hover {
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}

.sidebarReportNavBar {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #0f0f0f;
  position: relative;
  left: 0px;
  padding: 4px;
  z-index: 10;
}

.sidebarCloseBtn {
  background: #0f0f0f;
  float: left;
  margin-right: 5%;
}

.sidebarReportCloseBtn {
  background: #0f0f0f;
  float: right;
}

.sidebarMissionDetailsTitle {
  float: left;
  margin-left: 5%;
  width: 80%;
  height: 25px;
}

.sidebarMissionDetailsIcon {
  float: left;
  width: 15%;
  height: 25px;
}

.sidebarLaunchDetails {
  color: rgba(240, 240, 240, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20%;
  width: 100%;
  height: 20px;
  margin: 10px 0px 0px;
  float: left;
}

.sidebarLaunchDetails span {
  vertical-align: middle;
}

.sidebarLaunchDetails button {
  display: none;
  float: right;
  background-color: #0f0f0f;
}

.sidebarLaunchDetails:hover button {
  display: block;
}

.sidebarItemTitle {
  vertical-align: middle;
  float: left;
}

.sidebarSearchBtn {
  background: rgba(204, 245, 78, 0);
  float: right;
  transition: 300ms;
}

.sidebarSearchBtn:hover {
  scale: 1.3;
}

.activeReportBtn {
  border-radius: 4px;
  border: 0.5px solid #ccf54e !important;
  background: rgba(204, 245, 78, 0.1) !important;
}

.reportComponentSeparator {
  margin: 12px 6px;
  height: 0.5px;
  background-color: #f0f0f0;
}

.sidebarReportComponent {
  float: left;
  width: 20%;
  margin: auto;
  margin-right: 2%;
}

.sidebarReportComponentBtn {
  background-color: #0f0f0f;
  padding: 8px 12px;
  width: 100%;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.sidebarReportComponent button:hover {
  cursor: pointer;
  color: #f0f0f0;
  border-radius: 4px 4px 0px 0px;
  border-top: 0.5px solid #f0f0f0;
  background: rgba(240, 240, 240, 0.1);
}

.activesidebarReportComponent {
  border-radius: 4px 4px 0px 0px;
  border-top: 0.5px solid #ccf54e !important;
  background: rgba(204, 245, 78, 0.1) !important;
}

.addComponent {
  background: rgba(240, 240, 240, 0.05);
}

.reportDataBoxHeight {
  padding-bottom: 6px !important;
}

.reportWarningBox {
  display: flex;
  padding: 12px 6px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed #f2a93b;
  background: rgba(242, 169, 59, 0.1);
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}
