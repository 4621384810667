.map__container {
    height: 100%;
    width: 100%;
    position: relative;

    .cesium__container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;
    }
    .map__view {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        position: absolute;
        z-index: 90;
        flex: 1;
        top: 0;
        left: 0;
    }
}