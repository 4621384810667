.monitor__grid__view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: start;
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background: transparent;
    padding: 0px;
    gap: 8px;
}

.monitor__list__view {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex: 1;
    width: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background: transparent;
    padding: 0px;
    /* gap: 16px; */
}

.monitor__item__container {
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    padding: 0px;
    overflow: hidden;
    background: #191A1A;
    border-radius: 8px;
}

.panel__frame {
    width: 100%;
    height: 99%;
    border: none;
    border-radius: 8px;
    padding: 0px !important;
    margin: 0px !important;
    scrollbar-width: none !important;
    background: transparent;
    overflow: hidden !important;
}

.monitor__container__body__header {
    /* display: flex; */
    align-items: center;
    width: 100%;
    gap: 12px;
    padding-bottom: 15px;
    
    .buttonwrapper{
        display:flex;
    }

    .button {
        display: flex;
        padding: var(--8, 8px) 12px;
        margin-left: auto;
        margin-right: 8px;
        align-items: center;
        justify-content: space-between;
        border-radius: var(--4, 4px);
        background: rgba(240, 240, 240, 0.05);
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.8;
        transition: all 0.1s ease;

        &:hover {
            border: 0.5px solid rgba(204, 245, 78, 0.60);
        }

        &.active {
            border: 0.5px solid rgba(204, 245, 78, 0.60);
            background: rgba(204, 245, 78, 0.10);
            color: #CCF54E;
        }

        &:disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    }
}