.main_report_container{
    position: absolute;
    margin-left: 235pt;
    display: flex;
    width: 320pt;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--none, 0pt) 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 1);
    box-shadow: 0pt 0pt 20pt 0pt rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125pt);
}

.report_container_body{
    display: flex;
    padding: var(--12, 12pt);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--12, 12pt);
    align-self: stretch;
    border-radius: var(--none, 0pt) var(--12, 12pt) var(--12, 12pt) var(--none, 0pt);
}

.eclipse_report{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    align-self: stretch;
}

.deorbit_report{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    align-self: stretch;
}

.deorbit_report_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 13pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.45;
    }
}

.report_title{
    display: flex;
    align-items: center;
    gap: 4pt;
    span{
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 13pt;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.45;
        }
}

.population_section{
    display: flex;
    padding: 4pt 8pt;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    border-radius: 4pt;
    background: rgba(204, 245, 78, 0.50);
    span{
        color: #FFF;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.15pt;
    }
}

.report_body{
    display: flex;
    padding: 8pt;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--12, 12pt);
    align-self: stretch;
    border-radius: 4pt;
    background: rgba(95, 95, 95, 0.07);
}

.report_body_item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.report_body_item span:nth-child(1) {
    color: rgba(255, 255, 255, 0.50);
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.report_body_item span:nth-child(2) {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}