
.popup-modal{
    display: 'none';
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    background: #0B0C0D;
    backdrop-filter: blur(125px);
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
}
.popup-modal.expand {
    .show_more_details{
        display: flex;
    }
    .hide_more_details{
        display: none;
    }
}

.popup-modal-header{
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.popup-modal-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #1F201A;
}

.hide_more_details{
    display: flex;
}
.show_more_details{
    display: none;
}


.popup_modal_content_seperator{
    border-left: 1px solid rgba(225, 255, 255, .2) !important;
}

.provider-name {
    display: inline-block;
    max-width: 100%; /* Ensure truncation respects container width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end; /* Align text to the right */
}

.provider-label {
    text-align: end; /* Align "Provider" text to the right */
}