.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-success {
    min-height: 36px !important;
    background: #2F361A1A;
    width: fit-content;
    height: 36px !important;
    /* top: 69px;
        left: 580px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #253003;
    backdrop-filter: blur(50px);
    box-shadow: 0px 4px 20px 0px #0F052940;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,<svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.9375 6.9375L6.3125 11.3125L15.0625 1.9375" stroke="%23CCF54E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
        background-repeat: no-repeat;
        display: block;
        width: 17px;
        height: 13px;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 8px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-success div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: rgba(204, 245, 78, 1) !important;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-info {
    min-height: 36px !important;
    background: rgba(43, 87, 134, 0.6) !important;
    width: fit-content;
    height: 36px !important;
    /* top: 69px;
        left: 580px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #2b5586;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"%3E %3Cpath d="M10 17.5015C14.1421 17.5015 17.5 14.1436 17.5 10.0015C17.5 5.85933 14.1421 2.50146 10 2.50146C5.85786 2.50146 2.5 5.85933 2.5 10.0015C2.5 14.1436 5.85786 17.5015 10 17.5015Z" stroke="%23B5D7FF" stroke-linecap="round" stroke-linejoin="round"/%3E %3Cpath d="M10 6.66895H10.0083V6.67728H10V6.66895Z" stroke="%23B5D7FF" stroke-width="1.5" stroke-linejoin="round"/%3E%3Cpath d="M10 10.002V13.3353" stroke="%23B5D7FF" stroke-linecap="round" stroke-linejoin="round"/%3E %3C/svg%3E') !important;
        background-repeat: no-repeat;
        display: block;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 8px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-info div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #b5d7ff !important;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-error {
    min-height: 36px !important;
    background: #2F361A1A;
    width: fit-content;
    height: 36px !important;
    /* top: 69px;
        left: 500px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #330404;
    backdrop-filter: blur(50px);
    box-shadow: 0px 4px 20px 0px #0F052940;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 10.375L5.875 5.6875M10.25 1L5.875 5.6875M5.875 5.6875L1.5 1M5.875 5.6875L10.25 10.375" stroke="%23FF3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
        background-repeat: no-repeat;
        display: block;
        width: 17px;
        height: 11px;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 5px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-error div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #ff3333 !important;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-success-error {
    min-height: 36px !important;
    background: #2F361A1A;
    width: fit-content;
    height: 36px !important;
    /* top: 69px;
        left: 580px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #330404;
    backdrop-filter: blur(50px);
    box-shadow: 0px 4px 20px 0px #0F052940;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,<svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.9375 6.9375L6.3125 11.3125L15.0625 1.9375" stroke="%23ff3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
        background-repeat: no-repeat;
        display: block;
        width: 17px;
        height: 13px;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 8px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-success-error div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #ff3333 !important;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-name-error {
    min-height: 36px !important;
    background: #2F361A1A;
    width: 440px;
    height: 36px !important;
    /* top: 69px;
        left: 500px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #330404;
    backdrop-filter: blur(50px);
    box-shadow: 0px 4px 20px 0px #0F052940;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 10.375L5.875 5.6875M10.25 1L5.875 5.6875M5.875 5.6875L1.5 1M5.875 5.6875L10.25 10.375" stroke="%23FF3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
        background-repeat: no-repeat;
        display: block;
        width: 17px;
        height: 11px;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 5px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-name-error div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #ff3333 !important;
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-characters-error {
    min-height: 36px !important;
    background: #2F361A1A;
    width: 370px;
    height: 36px !important;
    /* top: 69px;
        left: 500px !important; */
    padding: 0 !important;
    gap: 8px;
    border-radius: 4px;
    border: 0.5px solid #330404;
    backdrop-filter: blur(50px);
    box-shadow: 0px 4px 20px 0px #0F052940;

    .Toastify__toast-icon {
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 10.375L5.875 5.6875M10.25 1L5.875 5.6875M5.875 5.6875L1.5 1M5.875 5.6875L10.25 10.375" stroke="%23FF3333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
        background-repeat: no-repeat;
        display: block;
        width: 17px;
        height: 11px;
        overflow: hidden;
        mask-image: none !important;
        margin-left: 4px;
        margin-right: 5px !important;

        svg {
            height: 0px;
            width: 0px;
        }
    }
}

.Toastify__toast-container .Toastify__toast.Toastify__toast-theme--light.custom-toast-characters-error div {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    color: #ff3333 !important;
}

.align__toast__position {
    top: 100px;
    right: 10px;
}
.align__toast__position__top {
    top: 60px;
    right: 20px;
}
