/* // CONTACT STYLE // */

.hud__ground__contact__container{
    height: 100px;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    gap: 10px;
}
.onboard__contact__container{
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.contact__container{
    display: flex;
    width: 100%;
    font-size: 12px;
    position: relative;
    overflow: visible;
    transition: all 0.5s ease;
    &:hover{
        .timeframe__change__button{
            width: 30px;
            padding: 10px 8.75px;
            background: rgba(240, 240, 240, 0.10);
            backdrop-filter: blur(10px);
            svg{
                display: flex;
            }
        }
    }
}


.onboard__contact__details__container__wrapper{
    height: 100%;
    width: 200px;
    display: flex;
    align-items: end;
    background: black;
    z-index: 999;
}
.onboard__contact__details__container{
    height: 100px;
    width: 188px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    z-index: 999;
    gap: 12px;
    .left_details{
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px;
        justify-content: space-between;
        color: #AEAEAE;
        border-right: 0.5px solid rgba(227, 227, 227, 0.48);
        .info__svg__container{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:hover .custom__tooltip{
                display: flex;
            }
            .custom__tooltip{
                display: none;
                position: absolute;
                width: 250px;
                bottom: 20px;
                padding: 6px 12px;
                border-radius: 4px;
                border: 0.5px solid rgba(240, 240, 240, 0.20);
                color: #d8d8d8;
                background: #19191A;
                backdrop-filter: blur(10px);
                z-index: 999;
                transition: all 0.3s ease;
            }
        }
    }
}

.timeframe__contact__details__container{
    width: calc(100% - 220px);
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.details__container__wrapper{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 12px 0px;
}

.contact__timeframe__container{
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid rgba(240, 240, 240, 0.20);
    height: 40px;
    position: relative;
    overflow: visible;
    &:hover{
        .current__time__indicator{
            height: 1000px;
            .indicator__tooltip{
                display: flex;
            }
        }
        .indicator__tooltip{
            display: flex;
        }
        .timeframe__change__button{
            width: 30px;
            padding: 10px 8.75px;
            background: rgba(240, 240, 240, 0.10);
            backdrop-filter: blur(10px);
            svg{
                display: flex;
            }
        }
    }
}

.timeframe__change__animation{
    opacity: 0;
    transition: all 0.5s ease !important;
}

.task__details__container{
    display: flex;
    height: 40px;
    flex: 1;
    position: relative;
    overflow: visible;
}

.task__details__card{
    display: flex;
    height: 35px;
    position: absolute;
    transform: translateX(0);
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    .event__indicator{
        position: absolute;
        top: -6px;
        right: -6px;
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        background: black;
        border-radius: 100%;
    }
    .task__card__container{
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: start;
        padding: 8px 8px;
        position: relative;
        .task_card_svg_container{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 20px;
        }
        .task__title{
            overflow: hidden;
            color: #B6B6B6;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: none;
            &.show{
                display: block;
            }
        }
    }

    &.past__event{
        background: rgba(240, 240, 240, 0.20);
        box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
        backdrop-filter: blur(5px);
    };
    &.ongoing__event{
        border: 0.5px solid #CCF54E;
        background: rgba(204, 245, 78, 0.10);
        box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
        backdrop-filter: blur(5px);
    };
    &.upcomming__event{
        border: 0.5px solid rgba(240, 240, 240, 0.50);
        background: rgba(240, 240, 240, 0.10);
        box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
        backdrop-filter: blur(5px);
    }
} 

.timeframe__datetime__container{
    width: 100%;
    position: absolute;
    top: 0;
    justify-content: space-between;
    padding-top: 5px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: visible;
    .contact__timeframe__date{
        color: rgba(240, 240, 240, 0.50);
        font-weight: 400;
    };
    .contact__timeframe__time{
        color: rgba(240, 240, 240, 0.80);
        font-weight: 500;
    }
}

.timeframe__slider__input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    border-bottom: 0.5px solid rgba(240, 240, 240, 0.05);
    background: transparent; 
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: start;
    padding-top: 5px;
    z-index: 99;
    &:disabled{
        cursor: default;
        pointer-events: none;
    }
}

.timeframe__slider__input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 50px;
    width: 2px;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.timeframe__datetime__indicators {
    position: absolute;
    width: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    height: 40px;
    bottom: -20px;
}

.timeframe__datetime__indicator__container{
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 0.5px solid;
    background: transparent;
    padding: 0px;
    &:disabled{
        cursor: default;
    }
    
}

.timeframe__change__button{
    display: flex;
    position: absolute;
    background: transparent;
    z-index: 99;
    top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12.5px;
    transition: all 0.3s ease;
    svg{
        display: none;
    }
    &.left{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        left: -15px;
        // left: -10px;
    }
    &.right{
        right: -20px;
        // right: -10px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        svg{
            transform: rotate(180deg);
        }
    }
    &:not(:disabled):hover{
        background: rgba(240, 240, 240, 0.25);
        svg{
            display: flex;
        }
    }
    &:disabled{
        cursor: default;
        opacity: 0.9;
        pointer-events: none;
    }
    &.hide__button{
        display: none;
    }
}

.timeframe__datetime__indicator {
    position: relative;
    margin-bottom: 12px;
    &:first-child{
        margin-left: -5px;
    }
    &:last-child{
        margin-right: -5px;
    }
}

.current__time__indicator{
    display: flex;
    position: absolute;
    top: 5px;
    width: 1px;
    background: rgba(240, 240, 240, 0.20);
    align-items: center;
    justify-content: center;
    z-index: 99;
    &::after{
        content: '';
        height: 12px;
        width: 12px;
        background: #CCF54E;
        top: 0;
        position: absolute;
        border-radius: 50%;
    };
    &::before{
        content: '';
        position: absolute;
        height: 50px;
        width: 2px;
        background: #CCF54E;
        top: 0px;
    };
}
.simulation__time__highlight__tooltip,
.indicator__tooltip{
    display: none;
    scale: 1;
    opacity: 1;
    position: absolute;
    top: -40px;
    padding: 8px 12px;
    color: #F0F0F0;
    width: max-content;
    flex: 1;
    z-index: 9999;
    user-select: none;
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: #000;
    backdrop-filter: blur(30px);
}

.simulation__time__highlight{
    display: flex;
    position: absolute;
    background: #aaa;
    width: 0px;
    height: 1px;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: space-between;
    
    .simulation__time__highlight__start__point{
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #aaa;
        position: absolute;
        left: -4px;
        z-index: 99;
        &:hover{
            .simulation__time__highlight__tooltip{
                display: flex;
            }
        }
    }
    .simulation__time__highlight__end__point{
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #aaa;
        position: absolute;
        right: -4px;
        z-index: 99;
        &:hover{
            .simulation__time__highlight__tooltip{
                display: flex;
            }
        }
    }
}

.contacts_overview{
    display: flex;
    width: 400px;
    color: #F0F0F0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--4, 4px);
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: #0B0C0D;
    box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.30);
    margin-bottom: 10px;
    z-index: 9999;
    .contacts_overview__header{
        gap: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    }
    .contacts_overview__body{
        display: flex;
        gap: 12px;
        flex-direction: column;
        width: 100%;
        background: #1F201A;
        padding: 12px;
    }
    .separator{
        height: 30px;
        width: 1px;
        background: rgba(240, 240, 240, 0.20);
    }
    .contact_title{
        display: flex;
        max-width: 98%;
        align-items: center;
        justify-content: start;
        span{
            font-family: Montserrat;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: auto !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: start;

            &:nth-child(2){
                margin-left: 4px;
                font-size: 14px !important;
                opacity: 1 !important;
                color: #CCF54E;
            }
        }
    }
    .task__status__badge{
        display: flex;
        padding: 4px 6px;
        border-radius: 4px;
        background: rgba(240, 240, 240, 0.20);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        &.ongoing{
            color: #CCF54E;
            border: 0.5px solid #CCF54E;
            background: rgba(204, 245, 78, 0.20);
        }
        &.band{
            background: rgba(0, 0, 0, 0.20);
        }
    }
    .contact_info{
        display: flex;
        flex-direction: column;
        width: 30%;
        align-items: start;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        gap: 2px;
        .band{
            display: flex;
            align-items: center;
            span{
                font-size: 12px !important;
                color: #F0F0F0 !important;
                opacity: 1 !important;
            }
        }
        span{
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            &:nth-child(2){
                opacity: 0.6;
                font-size: 10px;
            }
        }
        &:nth-child(1){
            width: 40%;
        }
    }
}

.timeLineToolTip{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    opacity: 0.6;
    align-self: start;
}

.timeLineToolTipText{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    align-self: start;
}
.station-name {
    display: inline-block;
    max-width: 100%; /* Ensure truncation respects container width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end; /* Align text to the right */
}