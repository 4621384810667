/*----- Ribbons -----*/

.ribbone {
	width: 100%;
	position: relative;
	background-size: cover;
	text-transform: uppercase;
	color: $white;
}

@media (max-width: 500px) {
	.ribbone {
		width: 100%;
	}
}

.ribbone1 {
	position: absolute;
	top: -6.1px;
	inset-inline-start: 10px;
	z-index: 1;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 20px solid transparent;
		border-inline-end: 24px solid transparent;
		border-top: 13px solid #f8463f;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #f8463f;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-start-end-radius: 8px;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			inset-inline-start: -6px;
			top: 0;
			background: #f8463f;
		}
		&:after {
			height: 6px;
			width: 8px;
			inset-inline-start: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #c02031;
		}
	}
}

// ARROW RIBBON
.arrow-ribbone-left {
	color: $white;
	padding: 2px 8px;
	position: absolute;
	top: 10px;
	inset-inline-start: 0;
	z-index: 6;
	font-size: 13px;
	&.bg-purple:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid #6d33ff;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-danger:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 112px solid transparent;
		border-inline-start: 12px solid $danger;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-primary:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-secondary:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid $secondary;
		border-bottom:11px solid transparent;
		width: 0;
	}
	&.bg-success:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid $primary-1;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-info:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid $info;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-warning:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid $yellow;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-pink:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -12px;
		border-top: 12px solid transparent;
		border-inline-start: 12px solid $pink;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-teal:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $teal;
		border-bottom: 11px solid transparent;
		width: 0;
	}
}
.arrow-ribbone-right {
	color: $white;
	padding: 2px 8px;
	position: absolute;
	top: 10px;
	inset-inline-end: 0;
	z-index: 6;
	font-size: 13px;
	&.bg-purple:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid #6d33ff;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-danger:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $danger;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-primary:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-secondary:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $secondary;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-success:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $primary-1;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-info:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $info;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-warning:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $yellow;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-pink:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $pink;
		border-bottom: 11px solid transparent;
		width: 0;
	}
	&.bg-teal:before {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-start: -12px;
		border-top: 12px solid transparent;
		border-inline-end: 12px solid $teal;
		border-bottom: 11px solid transparent;
		width: 0;
	}
}

.arrow-ribbone2 {
	color: $white;
	padding: 3px 8px;
	position: absolute;
	top: 10px;
	inset-inline-start: -1px;
	z-index: 6;
	font-size: 30px;
	&:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -24px;
		border-top: 24px solid transparent;
		border-bottom: 24px solid transparent;
		width: 0;
	}
}

.ribbone {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	z-index: 6;
}

.power-ribbone {
	width: 56px;
	height: 56px;
	overflow: hidden;
	position: absolute;
	z-index: 6;
	span {
		position: absolute;
		display: block;
		width: 82px;
		padding: 8px 0;
		color: $white;
		font: 500 16px/1 Lato, sans-serif;
		text-shadow: 0 1px 1px $black-2;
		text-transform: capitalize;
		text-align: center;
	}
}

// POWER RIBBON
.power-ribbone-top-left {
	span {
		inset-inline-end: -5px;
		top: 1px;
		transform: rotate(-45deg);
		i {
			transform: rotate(45deg);
			padding-top: 2px;
			padding-inline-start: 7px;
		}
	}
	top: -6px;
	inset-inline-start: -9px;
}

.power-ribbone-bottom-left {
	span {
		inset-inline-end: -5px;
		bottom: 1px;
		transform: rotate(45deg);
		i {
			transform: rotate(-45deg);
			padding-bottom: 2px;
			padding-inline-start: 7px;
		}
	}
	bottom: -6px;
	inset-inline-start: -9px;
}
.power-ribbone-top-right {
	span {
		inset-inline-start: -5px;
		top: 1px;
		transform: rotate(45deg);
		i {
			transform: rotate(-45deg);
			padding-top: 2px;
			padding-inline-end: 7px;
		}
	}
	top: -6px;
	inset-inline-end: -9px; 
}

.power-ribbone-bottom-right {
	span {
		inset-inline-start: -5px;
		bottom: 1px;
		transform: rotate(-45deg);
		i {
			transform: rotate(45deg);
			padding-bottom: 2px;
			padding-inline-end: 7px;
		}
	}
	bottom: -6px;
	inset-inline-end: -9px;
}

.ribbone {
	&::after,
	&::before {
		position: absolute;
		z-index: -1;
		content: '';
		display: block;
		border: 5px solid;
	}
	span {
		position: absolute;
		display: block;
		width: 225px;
		padding: 3px 0;
		box-shadow: 0 5px 10px $black-1;
		color: $white;
		text-shadow: 0 1px 1px $black-2;
		text-transform: capitalize;
		text-align: center;
		font-size: 11px;
	}
}

.ribbone-top-left {
	top: -10px;
	inset-inline-start: -10px;
	&::after {
		border-top-color: transparent;
		border-inline-start-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-inline-start-color: transparent;
		top: 0;
		inset-inline-end: 50px;
	}
	&::after {
		bottom: 50px;
		inset-inline-start: 0;
	}
	span {
		inset-inline-end: 0px;
		top: 24px;
		transform: rotate(-45deg);
	}
}
.ribbone-top-right {
	top: -10px;
	inset-inline-end: -10px;
	&::after {
		border-top-color: transparent;
		border-inline-end-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-inline-end-color: transparent;
		top: 0;
		inset-inline-start: 50px;
	}
	&::after {
		bottom: 50px;
		inset-inline-end: 0;
	}
	span {
		inset-inline-start: 0px;
		top: 24px;
		transform: rotate(45deg);
	}
}

.ribbone-warning-right {
	position: absolute;
    top: -8px;
    inset-inline-end: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 17px solid transparent;
		border-inline-end: 17px solid transparent;
		border-top: 10px solid $warning;
		z-index: 6;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: $warning;
		font-size: 13px;
		line-height: 1;
		padding: 10px 2px 5px;
		border-start-end-radius: 10px;
    	z-index: 6;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 5px;
			width: 10px;
			inset-inline-start: -9px;
			top: 3px;
			background: #d19518;
		}
		&:after {
			inset-inline-end: -6px;
			background: #d19518;
			height: 6px;
			width: 10px;
			inset-inline-start: -9px;
			top: 0;
			border-start-start-radius: 50px;
			border-start-end-radius: 0;
			border-end-start-radius: 0;
			border-end-end-radius: 0;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.ribbone-danger-right {
	position: absolute;
    top: -8px;
    inset-inline-end: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 17px solid transparent;
		border-inline-end: 17px solid transparent;
		border-top: 10px solid $danger;
		z-index: 6;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: $danger;
		font-size: 13px;
		line-height: 1;
		padding: 10px 2px 5px;
		border-start-end-radius: 10px;
    	z-index: 6;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 5px;
			width: 10px;
			inset-inline-start: -9px;
			top: 3px;
			background: #a10c24;
		}
		&:after {
			inset-inline-end: -6px;
			background: #a10c24;
			height: 6px;
			width: 10px;
			inset-inline-start: -9px;
			top: 0;
			border-start-start-radius: 50px;
			border-start-end-radius: 0;
			border-end-start-radius: 0;
			border-end-end-radius: 0;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}

.ribbone-success-left {
	position: absolute;
    top: -8px;
    inset-inline-start: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 17px solid transparent;
		border-inline-end: 17px solid transparent;
		border-top: 10px solid #09ad95;
		z-index: 6;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #09ad95;
		font-size: 13px;
		line-height: 1;
		padding: 10px 2px 5px;
		border-start-start-radius: 10px;
    	z-index: 6;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 5px;
			width: 10px;
			inset-inline-end: -9px;
			top: 3px;
			background: #088773;
		}
		&:after {
			inset-inline-end: -6px;
			background: #088773;
			height: 6px;
			width: 10px;
			inset-inline-end: -9px;
			top: 0;
			border-start-start-radius: 0;
			border-start-end-radius: 50px;
			border-end-start-radius: 0;
			border-end-end-radius: 0;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.ribbone-info-left {
	position: absolute;
    top: -8px;
    inset-inline-start: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 17px solid transparent;
		border-inline-end: 17px solid transparent;
		border-top: 10px solid $info;
		z-index: 6;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: $info;
		font-size: 13px;
		line-height: 1;
		padding: 10px 2px 5px;
		border-start-start-radius: 10px;
    	z-index: 6;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 5px;
			width: 10px;
			inset-inline-end: -9px;
			top: 3px;
			background: #124a8f;
		}
		&:after {
			inset-inline-end: -6px;
			background: #124a8f;
			height: 6px;
			width: 10px;
			inset-inline-end: -9px;
			top: 0;
			border-start-start-radius: 0;
			border-start-end-radius: 50px;
			border-end-start-radius: 0;
			border-end-end-radius: 0;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}

// BOOKMARK RIBBONS
.bookmark-ribbone-success-left {
	position: absolute;
    inset-inline-start: 10px;
	color: $white;
	&:after {
		position: absolute;
    	content: "";
    	width: 0;
    	height: 0;
		z-index: 6;
		inset-inline-start: 0;
		border-inline-start: 18px solid $success;
		border-inline-end: 18px solid $success;
		border-bottom: 10px solid transparent;
	}
	span {
		position: relative;
    	display: block;
    	text-align: center;
   	    background:  $success;
    	font-size: 13px;
    	line-height: 1;
    	padding: 12px 3px 20px;
    	// border-start-start-radius: 10px;
    	z-index: 6;
		// &:after {
		// 	position: absolute;
		// 	content: "";
		// }
		// &:before {
		// 	position: absolute;
    	// 	content: "";
   		// 	 height: 7px;
   		// 	 width: 8px;
    	// 	inset-inline-end: -6px;
    	// 	top: 3px;
    	// 	background: $success;
		// }
		// &:after {
		// 	height: 4px;
		// 	width: 8px;
		// 	inset-inline-end: -5px;
		// 	top: 0;
		// 	border-radius: 10px 10px 0 0;
		// 	background: $success;;
		// }
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.bookmark-ribbone-danger-left {
	position: absolute;
    inset-inline-start: 10px;
	color: $white;
	width: 30px;
	&:after {
		position: absolute;
    	content: "";
    	width: 0;
    	height: 0;
		z-index: 6;
		inset-inline-start: 0;
		border-inline-start: 15px solid $danger;
		border-inline-end: 15px solid $danger;
		border-bottom: 8px solid transparent;
	}
	span {
		position: relative;
    	display: block;
    	text-align: center;
   	    background:  $danger;
    	font-size: 12px;
    	line-height: 1;
    	padding: 12px 0px;
    	z-index: 6;
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.bookmark-ribbone-secondary-right {
	position: absolute;
    inset-inline-end: 10px;
	color: $white;
	width: 30px;
	&:after {
		position: absolute;
    	content: "";
    	width: 0;
    	height: 0;
		z-index: 6;
		inset-inline-end: 0;
		border-inline-start: 15px solid $secondary;
		border-inline-end: 15px solid $secondary;
		border-bottom: 8px solid transparent;
	}
	span {
		position: relative;
    	display: block;
    	text-align: center;
   	    background:  $secondary;
    	font-size: 11px;
    	line-height: 1;
    	padding: 12px 0px;
    	z-index: 6;
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.bookmark-sideleft-ribbone-success-left {
	position: absolute;
    top: 10px;
	color: $white;
	&:after {
		position: absolute;
    	content: "";
    	width: 0;
    	height: 0;
		z-index: 6;
		top: 0;
		inset-inline-end: -8px;
		border-top: 13px solid $success;
		border-bottom: 13px solid $success;
		border-inline-end: 8px solid transparent;
	}
	span {
		position: relative;
    	display: block;
    	text-align: center;
   	    background:  $success;
    	font-size: 11px;
		padding: 5px 13px;
		z-index: 6;
		height: 26px;
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}
.bookmark-sideright-ribbone-primary-right {
	position: absolute;
    top: 10px;
	inset-inline-end: 0;
	color: $white;
	&:after {
		position: absolute;
    	content: "";
    	width: 0;
    	height: 0;
		z-index: 6;
		top: 0;
		inset-inline-start: -8px;
		border-top: 13px solid $primary-1;
		border-bottom: 13px solid $primary-1;
		border-inline-start: 8px solid transparent;
	}
	span {
		position: relative;
    	display: block;
    	text-align: center;
   	    background:  $primary-1;
    	font-size: 11px;
		padding: 5px 13px;
		z-index: 6;
		height: 26px;
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}

.ribbone-1 span:after {
	position: absolute;
	content: "";
}

.arrow-ribbone-2 {
	color: #FFF;
	padding: 3px 8px;
	position: absolute;
	z-index: 6;
	font-size: 20px;
	&:before {
		position: absolute;
		inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		inset-inline-end: -24px;
		border-top: 16px solid transparent;
		border-inline-start: 24px solid #6963ff;
		border-bottom: 16px solid transparent;
		width: 0;
	}
}
.ribbone-card{
	overflow: hidden;
}

// FULLWIDTH RIBBONS
.fullwidth-primary-ribbons {
	.bar {
		color: #FFF;
		background-color: $primary-1;
		padding: 5px;
		text-align: center;
		position: relative;
		border: 2px transparent solid;
		box-shadow: 0 10px 30px -10px gray;

		span {
			position: absolute;
			&:nth-child(1), &:nth-child(3) { 
				border-style: solid;
				border-width: 11px;
				z-index: -1;
			}
			&:nth-child(1) {
				border-color: transparent;
				top: 31px;
				inset-inline-start: -2px;
			}
			&:nth-child(3) {
				border-color: transparent;
				top: 31px;
				inset-inline-end: -2px;
			}
			&:nth-child(2), &:nth-child(4) {
				top: 18px;
				border-style: solid;
				border-width: 18px;
				z-index: -2;
			}
			&:nth-child(2) {
				border-color: $primary-1 $primary-1 $primary-1 transparent;
				inset-inline-start: -30px;
			}
			&:nth-child(4) {
				border-color: $primary-1 transparent $primary-1 $primary-1;
				inset-inline-end: -30px;
			}
		}
	}
}
.fullwidth-secondary-ribbons {
	.bar {
		color: #FFF;
		background-color: $secondary;
		padding: 5px;
		text-align: center;
		position: relative;
		border: 2px transparent solid;
		box-shadow: 0 10px 30px -10px gray;

		span {
			position: absolute;
			&:nth-child(1), &:nth-child(3) {
				border-style: solid;
				border-width: 11px;
				z-index: -1;
			}
			&:nth-child(1) {
				border-color: transparent;
				top: 31px;
				inset-inline-start: -2px;
			}
			&:nth-child(3) {
				border-color:transparent;
				top: 31px;
				inset-inline-end: -2px;
			}
			&:nth-child(2), &:nth-child(4) {
				top: 18px;
				border-style: solid;
				border-width: 18px;
				z-index: -2;
			}
			&:nth-child(2) {
				border-color:#09a2cf #09a2cf #09a2cf transparent;
				inset-inline-start: -30px;
			}
			&:nth-child(4) {
				border-color: #09a2cf transparent #09a2cf #09a2cf;
				inset-inline-end: -30px;
			}
		}
	}
}

// FULLWIDTH ARROW RIBBONS
.fullwidth-arrow-warning-ribbons {
	.bar {
		color: #FFF;
		background-color: $warning;
		padding: 5px;
		text-align: center;
		position: relative;
		border: 2px transparent solid;
		box-shadow: 0 10px 30px -10px gray;

		span {
			position: absolute;
			&:nth-child(1), &:nth-child(3) {
				border-style: solid;
				border-width: 11px;
				z-index: -1;
			}
			&:nth-child(1) {
				border-color: transparent;
				top: 31px;
				inset-inline-start: -2px;
			}
			&:nth-child(3) {
				border-color: transparent;
				top: 31px;
				inset-inline-end: -2px;
			}
			&:nth-child(2), &:nth-child(4) {
				top: 18px;
				border-style: solid;
				border-width: 18px;
				z-index: -2;
			}
			&:nth-child(2) {
				border-color: #e3a014 #e3a014 #e3a014 transparent;
				inset-inline-start: -30px;
			}
			&:nth-child(4) {
				border-top: 18px solid transparent;
				border-inline-start: 18px solid #e3a014;
				border-bottom: 18px solid transparent;
				border-inline-end: transparent;
				inset-inline-end: -20px;
				top: -2px;
			}
		}
	}
}
.fullwidth-arrow-danger-ribbons-right {
	.bar {
		color: #FFF;
		background-color: $danger;
		padding: 5px;
		text-align: center;
		position: relative;
		border: 2px transparent solid;
		box-shadow: 0 10px 30px -10px gray;

		span {
			position: absolute;
			&:nth-child(1), &:nth-child(3) {
				border-style: solid;
				border-width: 11px;
				z-index: -1;
			}
			&:nth-child(1) {
				border-color: transparent;
				top: 31px;
				inset-inline-end: -2px;
			}
			&:nth-child(3) {
				border-color: transparent;
				top: 31px;
				inset-inline-start: -2px;
			}
			&:nth-child(2), &:nth-child(4) {
				top: 18px;
				border-style: solid;
				border-width: 18px;
				z-index: -2;
			}
			&:nth-child(2) {
				border-color: #c10f2c transparent #c10f2c #c10f2c;
				inset-inline-end: -30px;
			}
			&:nth-child(4) {
				border-top: 18px solid transparent;
				border-inline-end: 18px solid #c10f2c;
				border-bottom: 18px solid transparent;
				border-inline-start: transparent;
				inset-inline-start: -20px;
				top: -2px;
			}
		}
	}
}
