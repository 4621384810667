.modal_header_main_container{
    width: 100%;
    display: flex;
    padding: 4pt 4pt 4pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-image: linear-gradient(135deg, #fff, #000) 1;
    border-bottom: 0.5pt solid #F0F0F0;
    background: rgba(255, 255, 255, 0.00);
}

.modal_header_right_panel{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.minimize_icon {
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
}

.model_header_actions_btn {
    display: flex;
    gap: 8px;
}

.close_icon{
    display: flex;
    cursor: pointer;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.modal_header_left_panel{
    display: flex;
    align-items: center;
    gap: 4pt;
    span{
        color: #CCF54E;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48pt;
    }
}