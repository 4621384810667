

.draggable_resizable{
  position: absolute;
  background: transparent;
  border-radius: 5px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .resizable-handler {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    z-index: 9999;
    opacity: 0;
    &:hover{
      opacity: 1;
      &.tl,
      &.tr,
      &.bl,
      &.br {
        width: 20px;
        height: 20px;
      }
      &.t,
      &.b{
        height: 20px;
      };
      &.r,
      &.l {
        width: 20px;
      }

      &.t{
        top: -10px;
      }
      &.l{
        left: -10px;
      }
      &.r{
        right: -10px;
      }
      &.b{
        bottom: -10px;
      }
    }

    &.t,
    &.b {
      width: 98%;
      height: 8px;
    }
    &.r,
    &.l {
      width: 8px;
      height: 98%;
    }

    &.tl,
    &.t,
    &.tr {
      top: -3px;
    }

    &.tl,
    &.l,
    &.bl {
      left: -3px;
    }

    &.bl,
    &.b,
    &.br {
      bottom: -3px;
    }

    &.br,
    &.r,
    &.tr {
      right: -3px;
    }

    &.l,
    &.r {
      margin-top: -3px;
    }

    &.t,
    &.b {
      margin-left: -3px;
    }
  }

  .rotate {
    position: absolute;
    left: 50%;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

}