.report-container-eclipse{
    overflow-y: scroll;
	overflow-x: hidden;
    height: 609px;
    background-color: #222222;
    margin-bottom: 20px;
    padding: 8px;
    
}

.report-container-deorbit{
    overflow-y: scroll;
	overflow-x: hidden;
    height: 699px;
    background-color: #222222;
    margin-bottom: 20px;
    padding: 8px;
    
}

.spaceobject-container{
    background-color: #222222;
    margin-top: 10px;

}

.plane-container{
    background-color: #222222;
    margin-top: 10px;
}
.satellite-container{
    background-color: #222222;
    /* border-bottom: 10px solid #0F0F0F; */
    margin-top: 10px;
}

.overview-list{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px;
    border-radius: 0.5rem;
    border: 0.5px solid #827AA9;
    background: #0F0F0F;
}
.satellite-dropdown-eclipse{
    position: sticky;
    left: 63%;
    background-color: #0F0F0F;
    border-radius: 5px;
    font-size: 0.85;
    cursor: pointer;
}
.overview-list-comment{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px;
    border-radius: 0.5rem;
    border: 0.5px solid #827AA9;
    background: #0F0F0F; 
    margin-bottom: 10px;
    margin-top: 10px;
	margin: 6px;
}

.reportDataValuesLabel-comment {
    color: rgba(240, 240, 240, 0.6);
    font-family: Montserrat;
    font-size: 0.8rem;
    text-align: left;
    float: left;
    gap: 0.75rem;
    padding: 0.5rem;
    align-self: stretch;
    font-weight: 400;
  }
  
  .reportDataValuesDivNum-comment {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 0.8rem; 
     text-align: right;
     font-weight: 400;
     gap: 0.75rem;
     padding: 0.5rem;
  }
  

  .pl {
	display: block;
	margin: 320px auto;
	width: 8em;
	height: auto;
}
.pl line {
	animation-duration: 3s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}
.pl__line1,
.pl__line9 {
	animation-name: line1;
}
.pl__line2,
.pl__line8 {
	animation-name: line2;
}
.pl__line3,
.pl__line7 {
	animation-name: line3;
}
.pl__line4,
.pl__line6 {
	animation-name: line4;
}
.pl__line5 {
	animation-name: line5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
}

/* Animations */
@keyframes line1 {
	from,
	8% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	18% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	28% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	38% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	48% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	53% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	56% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	60% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	70% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	80% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	90% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line2 {
	from,
	6% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	16% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	26% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	36% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	46% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	51% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	54% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	58% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	68% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	78% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	88% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	98%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line3 {
	from,
	4% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	14% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	24% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	34% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	44% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	49% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	52% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	56% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	66% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	76% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	86% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	96%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line4 {
	from,
	2% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	12% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	22% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	32% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	42% {
		opacity: 1;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	47% {
		opacity: 0;
		stroke-dashoffset: 31.99;
		transform: translate(8px,16px);
	}
	50% {
		animation-timing-function: steps(1,start);
		opacity: 0;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	54% {
		animation-timing-function: ease-out;
		opacity: 1;
		stroke-dashoffset: 32;
		transform: translate(0,16px);
	}
	64% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	74% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	84% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	94%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
@keyframes line5 {
	from {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	10% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	20% {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	30% {
		stroke-dashoffset: 0;
		transform: translate(0,0);
	}
	40% {
		stroke-dashoffset: -16;
		transform: translate(0,15px);
	}
	50% {
		stroke-dashoffset: -31;
		transform: translate(0,-48px);
	}
	58% {
		stroke-dashoffset: -31;
		transform: translate(0,8px);
	}
	65% {
		stroke-dashoffset: -31.99;
		transform: translate(0,-24px);
	}
	71.99% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -31.99;
		transform: translate(0,-16px);
	}
	72% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
	82% {
		stroke-dashoffset: 16;
		transform: translate(0,8px);
	}
	92%,
	to {
		stroke-dashoffset: 31.99;
		transform: translate(0,16px);
	}
}
