.satelliteCreationHeader {
    display: flex;
    padding: 8pt 4pt 8pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.02);
}

.svgContainerSat {
    height: 16pt;
    width: 16pt;
}

.headerText {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
}

.headerSubText {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 10pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.15pt;
    opacity: 0.5;
}

.creationState {
    display: flex;
    align-items: center;
    gap: 4pt;
}

.addIcon {
    cursor: pointer;
}

.satelliteCreationBody {
    display: flex;
    flex-direction: column;
    z-index: 99;
    overflow-y: auto;
    padding: 10px 6px;
    gap: 4px;
    height: calc(33vh - 50px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.bodyText {
    color: #FFF;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
    opacity: 0.3;
}

.create_satellite_alignment {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}