.modalSelectBtn {
  width: 100%;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalSelectBtn:hover {
  cursor: pointer;
}

.modalSelectBtn .modalSelectOptionBtn {
  background: rgba(15, 15, 15, 0.3);
  background-color: rgba(15, 15, 15, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
  backdrop-filter: blur(125px);
  color: #000;
}

.contextMenuDiv {
}

.css-1p3m7a8-multiValue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #f2a93b !important;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 14px;
}

.tableModalDiv {
  border-radius: 0px 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.2);
  background: rgba(15, 15, 15, 0.4);
  box-shadow: 0px 0px 20px 0px rgba(43, 43, 43, 0.3);
  backdrop-filter: blur(125px);
  position: absolute;
  left: 400px;
  top: 90px;
  transition: 300ms;
}

.tableModalDiv.isSidebarOpen {
  left: 620px;
}

.tableModalHeader {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #ccf54e;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  height: 30px;
}

.tableModalBlackButtonDiv {
  margin: 12px;
  height: 30px;
}

.tableModalTable {
  margin: 0px 12px 12px;
  width: calc(100% - 24px);
}

.tableModalSelect {
  background-color: transparent;
}

.tableModalColumn {
  max-width: 128px;
  width: 17%;
  margin: 2%;
  padding: 0px 6px;
  text-align: center;
}

.tableModalCopyColumn {
  width: 5%;
  padding: 0px 12px;
}

.tableModalRow {
  height: 40px;
}

.tableModalHeaderText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 128px;
}

.tableCopyBtn {
  background: transparent;
  transition: 300ms;
  padding: 0px;
}

.tableCopyBtn:hover {
  scale: 1.5;
}

.tableInputElement {
  border: 0px;
  border-bottom: 0.5px solid #2465b2;
  padding: 0px 12px;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  height: 35px;
}

.tableInputElement:placeholder-shown {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(240, 240, 240, 0.5);
}

.tableInputElement:not(:placeholder-shown) {
  background: rgba(255, 255, 255, 0);
  color: #f0f0f0;
}

.tableModalHeaderIcon {
  padding-right: 12px;
}

.tableModalElement {
  max-width: 128px;
  margin: 4px auto;
}

.addPanelDiv {
  margin: 0px 12px 12px;
  text-align: right;
}

.addTableModalPanelBtn {
  color: rgba(240, 240, 240, 0.7);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  background: transparent;
  transition: 300ms;
}

.addTableModalPanelBtn:hover {
  scale: 1.1;
}
