.boundingBoxWrapper {
  position: fixed;
  top: 90px;
  left: 40px;
  float: left;
  display: block;
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  background: var(--dark-background, #101923);
  width: 30%;
  transition: 300ms;

}


boundingBoxWrapper .sidebarOpen {
  left: 620px;
}

.bondingBoxWrapper .mapboxgl-control-container {
  position: absolute !important;
  top: 10%;
  left: 50%;
}

.bondingBoxWrapper .mapboxgl-ctrl-group button+button {
  border-top: 0px solid #fff !important
}

.bondingBoxWrapper .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  display: flex !important;
}

.bondingBoxWrapper .mapbox-gl-draw_ctrl-draw-btn {
  background-color: transparent;
}

.bondingBoxWrapper .mapboxgl-ctrl-group.mapboxgl-ctrl {
  margin-top: -36%;
  border-radius: 8px;
  /* border-bottom: 1px solid #f0f0f0; */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
  box-shadow: 0px 0px 40px 0px rgba(131, 131, 131, 0.3);
  padding: 6px 41px 6px 41px;

}

.bondingBoxWrapper .mapbox-gl-draw_polygon {
  background-image: url(../../ImageSvgs/AOI.svg) !important;
  margin: 0px 8px;
}

.bondingBoxWrapper .mapbox-gl-draw_trash {
  background-image: url(../../ImageSvgs/Trash-icon.svg) !important;
  margin: 0px 8px;
  background-size: 80%;
}

.bbFooter {
  position: absolute;
  right: 0;
  top: 100.2%;
  border-radius: 4px;
  background: #20563d;
  font-size: 12px;
  padding: 5px 15px 5px 15px;
}
