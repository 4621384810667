.new_mission_sidebar {
    display: flex;
    left: 0;
    width: 230pt;
    top: 1vh;
    bottom: 1vh;
    height: 98vh;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: var(--none, 0pt) 4pt 4pt var(--none, 0pt);
    border-right: 0.5pt solid rgba(240, 240, 240, 0.10);
    background: rgba(9, 9, 9, 0.50);
    backdrop-filter: blur(40pt);
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translate(-222pt);
    z-index: 10;
}

.new_mission_sidebar.active {
    transform: translate(0vh);
}

.new_mission_sidebar_button {
    position: absolute;
    right: -9pt;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
    margin-top: 45vh;
    transform: rotate(180deg);
}


.creation_modal {
    position: absolute;
    display: flex;
    width: 320pt;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--none, 0pt) 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 1);
    box-shadow: 0pt 0pt 20pt 0pt rgba(52, 52, 52, 0.30);
    /* backdrop-filter: blur(125pt); */
    margin-left: 238pt;
    transition: all 0.2s ease-in-out;
}

.sideBarSubSection {
    height: 33vh;
    overflow: hidden;
}

.editable-field {
    .input_modal_with_unit {
        font-size: 14px;
    }

    .input_modal.not_required {
        padding: 0 8px;
        width: 20vh;
    }
}