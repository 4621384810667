.bottombar_container{
    position: absolute;
    bottom: 15pt;
    display: flex;
    padding: var(--12, 12px) 90px;
    gap: var(--12, 12px);
    border-radius: 8px;
    border-top: 1px solid #F0F0F0;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
}

.activeAnalysis{
    border-radius: 4px;
    border: 0.5px solid #CCF54E;
    background: rgba(204, 245, 78, 0.10);
}

.activeAnalysis.coverage_analysis {
    svg {
        path {
            stroke: rgba(204, 245, 78, 1);
        }
    }
}

.activeAnalysis.gs_analysis {
    svg {
        path {
            fill: #CCF54E;
        }
    }
}

.svgContainerBottomBar{
    position: relative;
    display: flex;    
    width: var(--40, 40px);
    height: var(--40, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.tooltipText {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(9, 9, 9, 0.80);
    padding: 6px;
    position: absolute;
    z-index: 1;
    bottom: 150%; /* Position above the div */
    left: 50%;
    margin-left: -50px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap; /* Prevent line break in text */
}

.svgContainerBottomBar:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }