.labelColor{
    color: #CCF54E;
}

.lil-gui.autoPlace{
    display: none !important;
}
/* styles.css */
  .circle {
    width: 8px;
    height: 8px;
    background-color: #D7E9FE;
    border-radius: 50%;
  }
  .circle-yellow {
    width: 8px;
    height: 8px;
    background-color: #F2A93B;;
    border-radius: 50%;
    margin-left: 5px;
  }

  .circle-green {
    width: 8px;
    height: 8px;
    background-color: #CCF54E;
    border-radius: 50%;
    margin-left: 5px;
  }

  .circle-gray {
    width: 8px;
    height: 8px;
    background-color:  #6D6D6F;
    border-radius: 50%;
    margin-left: 5px;
  }

  .horizontal-line {
    border-bottom: 2px solid #2465B2; /* You can customize the color and thickness */
    width: 60px;
    margin-bottom: 30px;
    }
.circle {
    position: relative;
}

.custom-tooltip {
    position: absolute;
    top: 30px;
    left: 50%; /* Center the tooltip horizontally */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    width: 150px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
.circle:hover .custom-tooltip {
    opacity: 1;
}

.run-status {
    color: #CCF54E;
    font-family: Montserrat;
    font-size: 16px;
}

.control-mode-status {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 16px;
}

.run-mode {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.run-mode-box{
    width: 8%;
    border-radius: 8px;
    padding: 8px 12px;
    border: 0.5px solid rgba(240, 240, 240, 0.10);
    opacity: 0.8;
    background: rgba(160, 160, 160, 0.10);
}

.estimation-box{
    width: 46%;
    margin-left: 6px;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.10);
    background: rgba(160, 160, 160, 0.10);
}

.error-box{
    width: 40%;
    height: auto;
    flex: 1 0 0;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.10);
    background: rgba(160, 160, 160, 0.10);
}

.inner-box {
    display: flex;
    width: auto;
    padding: 8px;
    justify-content: space-between;
    
}

.innner-box-yellow-text{
    color: #F2A93B;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 13.2px */
}

.innner-box-green-text{
    color: #CCF54E;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 13.2px */
}

.innner-box-gray-text{
    color: #6D6D6F;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 13.2px */
}

/* YourComponent.css */

.error-box-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .error-box-component {
    /* Add your component styles here */
    width: auto; /* Set a specific width for the components */
    height: auto; /* Set a specific height for the components */
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    border: 1px solid rgba(160, 160, 160, 0.40);
    background: rgba(0, 0, 0, 0.10);
    padding: 8px;
}

.modal-style {
    border-radius: 8px;
    background: #0A0A0A;
}

.history-latest-container{
    border-left: 1.5px solid #C9EEFF;
    padding: 4px 8px;
    background: rgba(201, 238, 255, 0.10);
}

.history-old-container{
    padding: 4px 8px;
}

.history-main-text{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.history-sub-text{
    color: rgba(240, 240, 240, 0.50);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.history-error-text{
    color: #F33;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.history-success-text{
    color: #CCF54ECC;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.history-normal-text{
    color: #F0F0F0CC;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}