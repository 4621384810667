.radio__btn__group__container{
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    .radio__btn__container{
        display: flex;
        gap: 4px;
        align-items: center;
        opacity: 0.7;
        background: transparent;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease;
        &.active{
            opacity: 1;
            .radio__btn::after{
                content: '';
                height: 100%;
                width: 100%;
                border-radius: 100%;
                background: #F0F0F0;
            }
        }
        &:disabled{
            opacity: 0.5;
            pointer-events: none;
            cursor: default;
        }
        .radio__btn{
            display: flex;
            height: 12px;
            width: 12px;
            padding: 2px;
            border-radius: 100%;
            border: 1px solid #F0F0F0;
        };
        .radio__label{
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
        };
    }
}