.custom__selector__select__container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
    position: relative;
    cursor: pointer;
    width: 100%;
    background: transparent;
    .custom__selector__selected{
        display: flex;
        width: 100%;
        height: 32px;
        padding: 0px 12px;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        gap: 8px;
        background: transparent;
        border: 0.5px solid rgba(240, 240, 240, 0.20) ;
        .custom__selector__input__box{
            display: flex;
            flex: 1;
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            margin: 0px;
            color: #F0F0F0;
            font-family: Montserrat;
            color: #F0F0F0;
            outline: none;
            font-size: 12px;
            &::placeholder{
                color: rgba(255, 255, 255, 0.5);
            }
            &:focus{
                border: 0;
                outline: none;
            }
            &:disabled{
                pointer-events: none;
                cursor: default;
            }
        }
        .icon{
            transform: rotate(90deg);
            font-size: 18px;
            color: #E3E3E3;
            transition: all 0.3s ease;
        }
    };
    .custom__selector__option__container{
        display: none;
        flex-direction: column;
        height: 0;
        width: 100%;
        font-size: 12px;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        left: 0px;
        background: transparent;
        z-index: 999;
    };
    &.active{
        .custom__selector__selected > .icon{
            transform: rotate(0deg)
        }
        .custom__selector__option__container{
            display: block;
            height: auto;
            max-height: 300px;
            background: rgb(53 49 49 / 95%);
            border: 0.5px solid rgba(240, 240, 240, 0.20) ;
            backdrop-filter: blur(25px);
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            };
            &:hover{
                &::-webkit-scrollbar {
                    display: flex;
                };
            }

            .custom__selector__option{
                width: 100%;
                color: #F0F0F0;
                padding: 8px 25px 8px 12px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all 0.3s ease;
                cursor: pointer;
                background: transparent;
                &.unique_option{
                    font-style: italic;
                    background: rgba(27, 26, 26, 0.8);
                }
                &:hover{
                    background: rgba(240, 240, 240, 0.15);
                };
                &.selected{
                    background: rgba(240, 240, 240, 0.26);
                };
                &:disabled{
                    pointer-events: none;
                    cursor: default !important;
                    opacity: 0.5;
                    user-select: none;
                    &:hover{
                        background: transparent;
                    }
                };
                &.hide_option{
                    display: none;
                }
            };

            .custom__selector__option__container__empty{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 35px;
                width: 100%;
                background: #2e1a1a;
                color: #F0F0F0;
                font-family: Montserrat;
                font-size: 12px
            }
        }
    };
    &.disabled{
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
        user-select: none;
    }
}

.header__select__container{
    background: rgba(0, 0, 0, 0.20);
    width: 98px;
    border: none;
    font-size: 12px;
}