.add__device__container{
    background: #08172F !important;
    padding: 15px 15px!important;
    border: 1px solid rgba(190, 199, 216, 0.4);
    -webkit-backdrop-filter: blur(9.83232px);
    backdrop-filter: blur(9.83232px);
    border-radius: 8px;
}

.add_new_device__btn{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    height: auto !important;
    border-radius: 8px;
    background: rgba(77, 75, 75, 0.6);
    &:hover{
        background: rgba(77, 75, 75, 0.8);
    }
}
.device__input{
    border: 0.5px solid rgba(255, 255, 255, 0.4);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.delete__details__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, 0.10);
    border: 0.5px solid rgba(255, 0, 0, 0.40);
    &:hover{
        background-color: rgba(255, 0, 0, 0.40);
        border: 0.5px solid rgba(255, 0, 0, 0.60);
    }
}
.cancel__button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    height: auto !important;
    border-radius: 8px;
    background: transparent;
    border: 0.5px solid rgba(255, 0, 0, 0.40);
    &:hover:not(:disabled){
        border: 0.5px solid rgba(255, 0, 0, 0.60);
        background-color: rgba(255, 0, 0, 0.40);
    }
    &:disabled{
        cursor: auto;
        pointer-events: none;
        opacity: 0.6;
    }
}
.save__btn,
.add__more__btn{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    border-radius: 8px;
    background: transparent;
    background-color: rgba(109, 98, 98, 0.4);
    &:hover:not(:disabled){
        background-color: rgba(109, 98, 98, 0.6);
    }
    &:disabled{
        cursor: auto;
        pointer-events: none;
        opacity: 0.6;
    }
}