.visualization_container{
    height: 93vh;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 99;
}

.gs_list_container,
.gs_create_modal_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 0.7);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    position: absolute;
    transition: all 0.3s ease;
    z-index: 99;
}
.gs_list_container{
    top: 5%;
    left: 30px;
    width: 350px;
}
.gs_list_container.minimize{
    height: 45px;
    overflow: hidden;
    .gs_container_body{
        display: none;
    }
}
.gs_create_modal_container{
    top: 7%;
    width: 0px;
    height: 0px;
    overflow: hidden;
    left: 300px;
}

.gs_create_modal_container.active{
    top: 5%;
    width: 360px;
    height: 440px;
    left: 400px;
}

.gs_container_body,
.gs_create_modal_container_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: rgba(204, 245, 78, 0.05);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    border-top: 1px solid transparent; /* Set a transparent solid border initially */
    border-image: linear-gradient(to right, #B7B7B7, transparent); /* Adjust the gradient direction and colors as needed */
    border-image-slice: 1; /* Make the entire border a gradient */
}


.gs_create_modal_container_body{
    height: 340px;
    padding: 10px 15px;
    overflow-y: auto;
}
.gs_container_body_header{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 5px 10px;
    justify-content: space-between;
    width: 100%;
    background: rgba(187, 245, 78, 0.226);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
}
.gs_container_body_container{
    display: flex;
    flex-direction: column;
    height: 340px;
    padding: 10px 5px;
    gap: 8px;
    overflow-y: auto;
}
.add_ground_station_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background: transparent;
    .icon{
        color: #b8b8b8;
    }
}
.add_ground_station_btn:hover,
.add_ground_station_btn.active{
    backdrop-filter: blur(125px);
}

.gs_container_footer,
.gs_create_modal_container_footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    backdrop-filter: blur(125px);
    border-top: 1px solid transparent; /* Set a transparent solid border initially */
    border-image: linear-gradient(to right, #B7B7B7, transparent); /* Adjust the gradient direction and colors as needed */
    border-image-slice: 1; /* Make the entire border a gradient */
}

.popup-modal{
    display: 'none';
    flex-direction: column;
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    width: 600px;
    background: rgba(15, 15, 15, 0.70);
    backdrop-filter: blur(125px);
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
}
.popup_modal_content_seperator{
    border-left: 1px solid rgba(225, 255, 255, .2) !important;
}


.dropdown_menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    .dropdown_title{
        font-size: 14px;
        color: #F0F0F0
    }
}

.dropdown_content{
    display: none;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 5px;
}

.dropdown_content_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    border-radius: 5px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    transition: all 0.3s ease-in-out;
    cursor: pointer
}
.dropdown_content_item.active{
    border-radius: 5px;
    border: 0.5px solid #CCF54E;
    background: rgba(204, 245, 78, 0.10);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
}
.dropdown_content_item:hover{
    border: 0.5px solid rgb(255, 255, 255);
    background: rgba(15, 15, 15, 0.3);
}

.dropdown_item_batch{
    display: flex;
    padding: 4px 8px;
    border-radius: 4px;
    background: rgba(240, 240, 240, 0.10);
    color: #d8d8d8;
    font-size: 13px;
}

.toggle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8.66px solid #e1e1e1a0; /* Adjust the color as needed */
    border-radius: 3px;
    transform: rotate(90deg);
    transition: all 0.3s ease;
}
.toggle_filter_menu{
    width: 0;
    height: 0;
    border-top: 2px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8.66px solid #e1e1e1a0; /* Adjust the color as needed */
    border-radius: 3px;
    transform: rotate(90deg);
    transition: all 0.3s ease;
}

.dropdown_menu.active {
    .dropdown_title{
        color: #CCF54E;
    }
    .toggle_filter_menu,
    .toggle{
        transform: rotate(180deg);
        border-bottom: 8.66px solid #d8d8d8;
    };
    .dropdown_content{
        display: flex;
    };
}

.search_input_container{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    input{
        display: flex;
        padding: 0px 5px 0px 10px;
        width: 0px;
        height: 0px;
        background: transparent;
        border: none;
        outline: none;
        color: #d8d8d8;
        font-size: 14px;
        transition: 0.3s ease;
    };
}
.search_input_container.active{
    background: rgba(15, 15, 15, 0.6);
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    input{
        width: 170px;
        height: 30px;
        border-right: 0.5px solid rgba(240, 240, 240, 0.20);
    };
}
.search_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    color: #d8d8d8;
    font-size: 14px;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 8px;
    transition: all 0.3s ease-in;
}

.search_btn:hover,
.search_btn.active{
    backdrop-filter: blur(125px);
}

.filter_popup_modal{
    display: none;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(15, 15, 15, 1); /* Use rgba for transparency */
    box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(50px); /* Adjust the blur value to your liking */
    position: absolute;
    top: 45px;
    left: 0;
    transition: all 0.3 ease;
}
.filter_popup_modal.active{
    display: flex;
    flex-direction: column;
    gap: 8px;
    &::before {
        content: '';
        position: absolute;
        top: -10px; /* Adjust this value to control the distance of the arrow from the top */
        left: 7px; /* Adjust this value to control the distance of the arrow from the right */
        border-width: 0 10px 10px 10px; /* Create a triangular shape */
        border-style: solid;
        border-color: transparent transparent rgb(15, 15, 15, 0.9) transparent; /* Set the border color and transparency */
    }
    .filter_popup_modal_container{
        width: 200px;
        max-height: 250px;
        padding: 10px 15px;
        overflow-y: auto;
        display: flex;
        gap: 8px;
        flex-direction: column;
        transition: all 0.3 ease;
    }
    .reset__filter__btn{
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: rgba(204, 245, 78, 0.05);
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.other_provider_input_container{
    display: flex;
    color: #fff;
    background-color: transparent;
    border: 1px solid rgba(36, 101, 178, 0.6);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 7px;
    input{
        display: flex;
        padding: 0px 5px 0px 10px;
        width: 195px;
        height: 35px;
        background: transparent;
        border: none;
        border-right: 0.5px solid rgba(240, 240, 240, 0.20);
        outline: none;
        color: #d8d8d8;
        font-size: 14px;
    };
    .other_provider_close_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        outline: none;
        color: #d8d8d8;
        height: 35px;
        width: 35px;
        border-radius: 7px;
    }
    .other_provider_close_btn:hover{
        background: rgba(82, 22, 22, 0.439);
    }
}
