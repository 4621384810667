.grid-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  transition: 1s ease;
  position: relative;
}
.sidebarTmp {
  position: absolute !important;
  width: 720px;
  height: 100%;
  background-color: black;
  transition: 1s ease;
  z-index: 9999;
  box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.3);
  overflow-x: hidden;
}

.sidebar_small {
  width: 300px;
  overflow: overlay;
  box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.3);
}

.rightTqButton {
  position: absolute;
  border: none;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  left: 300px;
  top: 1.5rem;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 99999;
  color: black;
  transition: 1s ease;
  background: #252e0b;
  box-shadow: 0px 3.33333px 8.33333px 0px rgba(56, 56, 56, 0.25);
}
.leftTqButton {
  position: absolute;
  border: none;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  left: 720px;
  top: 1.5rem;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 99999;
  color: black;
  background: #252e0b;
  box-shadow: 0px 3.33333px 8.33333px 0px rgba(56, 56, 56, 0.25);
  transition: 1s ease;
}

.rightTqButton .fa {
  color: black;
}
.leftTqButton .fa {
  color: black;
}

.header-btn-list {
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: flex-start;
  margin: 20px 0;
  border-radius: 4px;
  overflow: hidden;
}
.header-tab-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px 20px;
  flex: 1;
  color: #f0f0f0;
  font-size: 14px;
  gap: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s, color 0.3s; /* Add a smooth transition */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.header-tab-btn.active {
  background-color: #ccf54e;
  color: #000000;
}

.taskQueueTeleModal {
  width: 670px;
  gap: 12px;
  background: #000000f5;
  border: 0.5px solid #f0f0f033;
  box-shadow: 0px 0px 20px 0px #3434344d;
  position: absolute;
  top: 60px;
  z-index: 999;
  border-radius: 8px;
  height: auto;
  transform: translate(-300%);
  transition: 1s ease;
}
.taskQueueTeleModal.active{
  transform: translate(0);
}

.taskQueueTeleModalHeader {
  width: 100%;
  padding: 12px;
  height: 64px;
}

.taskQueueTeleModalHeaderTitle {
  display: flex;
}

.taskQueueTeleModalCloseBtn {
  float: right;
  font-size: 36px;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 12px;
}

.taskQueueTeleModalHeaderTitle {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.taskQueueTeleModalHeaderTitleDark {
  color: #f0f0f080;
}

.taskQueueTeleModalHeaderTitleLight {
  color: #f0f0f0;
}

.taskQueueTeleModalContent {
  padding: 12px;
}

.expandedSubOrderTable {
  width: calc(100% - 24px);
  margin: 8px 12px 12px;
}

.expandedSubOrderTableHeaderRow {
  border-radius: 8px !important;
  overflow: hidden;
  border: 0.5px solid rgba(255, 255, 255, 0.2) !important;
}

.expandedSubOrderTableHeaderRow th {
  padding: 12px;
  color: var(--Active-Icons-Colour, rgba(240, 240, 240, 0.6));
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.expandedSubOrderTableSpikeItemRow {
  width: 100%;
  height: 60px;
}

.spikeItemText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spikeItemActionBtn {
  background-color: transparent;
  transition: 300ms;
}



.spikeItemActionBtn img {
  position: relative;
  left: 25px;
  top: 0px;
}

.orderTitleMainDiv {
  max-width: 285px;
}

.orderTitleDiv {
  min-width: 180px;
  width: auto;
  display: block;
  float: left;
  padding-left: 12px;
}

.subOrderTitleName {
  text-align: left;
  color: #e3e3e3;
  font-family: Montserrat;
  font-size: 13px;
  float: left;
  display: block;
}

.subOrderTitleName.grey {
  color: #e3e3e3;
  opacity: 0.65;
}

.subOrderTitleName.white {
  color: #f0f0f0;
}

.subOrderTitleName.yellow {
  color: #f2a93b;
}

.bottomBorder {
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #444, transparent) 1;
}

.topBorder {
  border-style: solid;
  border-width: 0px;
  border-top-width: 0.1px;
  border-image: linear-gradient(15deg, #444, transparent) 1;
}

.pauseIcon {
  position: relative;
  top: 4px;
  background: transparent;
}

.pauseIcon.noTop {
  top: 0px !important;
}

.pauseIcon.rotationIcon {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.orderProgressDivPendingBtn {
  width: 10%;
  margin: 0px 0px 0px 9px;
  display: block;
  float: right;
  background: transparent;
  transition: 300ms;
}


.taskQueueTeleModalContent.modalScrollableSection {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #20563d;
  scrollbar-width:inherit !important;
}

.taskQueueTeleModalContent.modalScrollableSection::-webkit-scrollbar {
  width: 3px;
}

.taskQueueTeleModalContent.modalScrollableSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.taskQueueTeleModalContent.modalScrollableSection::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.subOrderImg {
  margin-right: 6px;
  opacity: 0.65;
  float: left;
  position: relative;
  top: 4px;
}
