.custom__input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 32px;
    input[type="datetime-local"] { 
        color-scheme: dark; 
    }
    input{
        display: flex;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        border: 0.5px solid rgba(240, 240, 240, 0.30);
        background: rgba(10, 10, 10, 0.65);
        flex: 1;
        padding: 4px 8px;   
        color: #F0F0F0;
        outline: none;
        font-size: 12px;
        &::placeholder{
            color: rgba(255, 255, 255, 0.5);
        }
        &:focus{
            border: 0;
            outline: none;
        }
        &:disabled{
            opacity: 0.6;
            pointer-events: none;
            cursor: default;
        }
    }
}