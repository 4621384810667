.scenario__modal{
    height: 100%; 
    width: 100%; 
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 99;
}

.scenario__modal__container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(250, 250, 250, 0.1);
    background: black;
    border-radius: 12px;
    overflow: hidden;
    border: 0.5px solid rgba(240, 240, 240, 0.502);
}
.scenario__modal__container__header{
    width: 100%;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #272F1F;
}

.container__header__btn{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba(139, 156, 123, 0.05);
}
.container__header__btn:hover{
    border: 0.5px solid #88A26F;
}
.container__header__btn.active{
    border-radius: 4px;
    border: 0.5px solid #CCF54E;
    background: rgba(0, 0, 0, 0.40); 
}

.scenario__modal__container__second__header{
    width: 100%;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.scenario__modal__container__body{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
}
.schedule__table__header__container{
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 12px;
    font-size: 14px;
}
.schedule__item__details__header,
.schedule__table__header{
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.3);
    opacity: 0.8;
    padding: 8px 12px;
}
.schedule__table__body{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow-y: auto;
    padding: 0px 10px;
    padding-bottom: 20px;
    font-size: 12px;
}
.schedule__item__details__body{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    overflow-y: auto;
}
.schedule__item__details__body__row{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 8px;
}
.schedule__table__body__row{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    background: transparent;
}
.schedule__table__body__row.active{
    background: rgba(240,240, 240, 0.05);
}
.schedule__item__details__body__row__item,
.schedule__table__body__row__item{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
    .eye_btn{
        padding: 3px 9px;
        border-radius: 4px;
        background: transparent;
    }
}
.schedule__table__body__row__item.disabled,
.schedule__table__body__row__item.disabled:hover{
    opacity: 60%;
    background: transparent;
    cursor: auto;
}

.schedule__table__body__row__item:hover,
.schedule__table__body__row__item.active{
    cursor: pointer;
    background: rgba(204, 245, 78, 0.10);
    .eye_btn:hover,
    .eye_btn.active{
        background: rgba(192, 217, 249, 0.08);
    }
}
.schedule__item__details__body__separator{
    background: rgba(240, 240, 240, 0.1);
    width: 100%;
    height: 0.5px;
}

.schedule__table__body__row__item__details{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background: rgba(204, 245, 78, 0.05);
}

.task_schedule_separator_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px;
    gap: 30px;
    border-radius: 4px;
    background: rgba(191, 0, 255, 0.20);
}
.task_schedule_separator_border{
    display: flex;
    flex: 1;
    height: 1px;
    background: #f0f0f0;
    opacity: 0.5;
}

.download_btn_dropdown{
    position: absolute;
    min-width: 100px;
    top: 45px;
    right: -45px;
    border-radius: 4px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(15px);
    display: none;
    flex-direction: column;
    align-items: flex-start;
    z-index: 99;
}

.download_btn_dropdown.active{
    display: flex;
    &::before {
        content: '';
        position: absolute;
        top: -11px; /* Adjust this value to control the distance of the arrow from the top */
        right: 65px; /* Adjust this value to control the distance of the arrow from the right */
        border-width: 0 10px 10px 10px; /* Create a triangular shape */
        border-style: solid;
        border-color: transparent transparent rgb(0, 0, 0, 0.80) transparent; /* Set the border color and transparency */
    }
}

.download_btn_dropdown .download_button{
    background: transparent;
    padding: 4px 12px;
    font-size: 12px;
    width: 100%;
    display: flex;
    gap: 7px;
    align-items: center;
    svg{
        stroke: #F0F0F0
    }
}
.download_btn_dropdown.active .download_button:hover{
    background: rgba(240, 240, 240, 0.15);
    color: #CCF54E;
    svg{
        stroke: #CCF54E;
    }
}

.bg_light_gray{
    background: rgba(255,255,255,0.1);
}

.graph_container{
    background-color: #111509;
    border-radius: 5px;
    transition: all 0.1s ease;
    iframe{
        height: 320px;
        width: 100%;
        border-radius: 5px;
        transition: all 0.1s ease;
        margin: 0px !important;
        padding: 0px !important;
        overflow: hidden;
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
}
