.menu_item_container{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    padding: 4pt 2pt;
    border-radius: 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.10);
    background: rgba(9, 9, 9, 1.0);
    backdrop-filter: blur(20pt);
    cursor: pointer;
    z-index: 999;
    &.show_menu{
        display: flex;
    }
}

.groundStations{
    width: 130pt;
}

.menu_item{
    display: flex;
    padding: 4pt;
    align-items: center;
    align-self: stretch;
    gap: 4pt;
    border-radius: 2px;
    span{
        color: #F0F0F0;
        text-align: center;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &:hover{
        background-color: rgba(240, 240, 240, 0.10);
    }
}

.right_heder_icon_aoi{
    display: none;
    padding-top: 4px;
    padding-right: 12px;
    cursor: pointer;
    &.show{
        display: flex;
    }
}