.analyticsFeaturesBar {
  z-index: 1;
}

.analyticsFeaturesBar .ucpFeaturesBar {
  top: 12px;
  right: 6px;
  z-index: 2;
}

.analyticsFeaturesBar .ucpAdditionalFeaturesBar {
  top: 0px;
  right: 70px;
  z-index: 2;
}

.analyticsFeaturesBar .ucpAdditionalFeaturesBar .featuresList.itemOne {
  top: 75px;
}

.analyticsFeaturesBar .ucpAdditionalFeaturesBar button:nth-child(2) {
  display: none;
}

.rightSideAnalyticsBar {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  background: #101012;
  position: absolute;
  top: 60px;
  right: 0px;
  width: 400px;
  height: calc(100% - 115px);
  padding: 12px;
  max-height: calc(100% - 115px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.analyticsTopRightChartDiv {
  border: 0.5px solid #464646;
  background: #101012;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.anlyticsTopRightChart {
  width: 100%;
}

.anlyticsTopRightChart canvas {
  scale: 0.95;
}

/* .anlyticsTopRightChart div canvas{
    height: 100% !important;
} */

.anlyticsTopRightChartHeaderDiv {
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
}

.anlyticsTopRightChartHeaderDiv :nth-child(1) {
  color: var(--Dark-Content-1, #e8e8e8);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.anlyticsTopRightChartHeaderDiv :nth-child(2) {
  color: var(--Active-Green, #ccf54e);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.analyticsDataBoxes {
  display: flex;
  width: 380px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6.468px;
  position: absolute;
  bottom: 68px;
  left: 12px;
}

.analyticsDataBoxesRow {
  display: flex;
  gap: 1.617px;
  align-self: stretch;
}

.analyticsDataBox {
  display: flex;
  min-width: 80.032px;
  width: max-content;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 3.234px;
  background: rgba(240, 240, 240, 0.05);
}

.analyticsDataBoxHeader {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12.936px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.analyticsDataBoxSubHeader {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 9.702px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.analyticsBarFiltersDiv {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
}

.analyticsBarFilterItems {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: #181818;
  box-shadow: 0px 4px 12px 0px rgba(10, 10, 10, 0.3);
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.analyticsOrderSelectionDiv {
  display: flex;
  height: var(--icon-sizes-3xl, 40px);
  padding: 1px 8px;
  align-items: center;
  gap: 12px;
  position: absolute;
  left: 12px;
  top: 84px;
  border-radius: 4px;
  background: #050505;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.analyticsOrderSelectionDiv button {
  transition: 300ms;
  background: transparent;
}

.analyticsOrderSelectionDiv button:hover {
  scale: 2;
}

.analyticsOrderSelectionDiv button .imgFlipped {
  transform: rotate(180deg);
}
